import React from 'react'
import RouteEnum from "../../../../constants/RouteEnum";

class Link extends React.Component {

    render() {

        if (this.props.type === 'extern'){

            return (

                <a href={this.props.link_extern} target='_blank' className="c-link-blocks__single">
                    <div className="c-link-blocks__single__header">
                        <div className="c-link-blocks__single__text">
                            <span>{this.props.titel}</span>
                            <small>{this.props.ondertekst}</small>
                        </div>
                        <div className="c-link-blocks__single__cta">
                            <i className="icon-internal"/>
                        </div>
                    </div>
                </a>

            )

        }else {

            return (

                <a onClick={() => this._click()} className="c-link-blocks__single">
                    <div className="c-link-blocks__single__header">
                        <div className="c-link-blocks__single__text">
                            <span>{this.props.titel}</span>
                            <small>{this.props.ondertekst}</small>
                        </div>
                        <div className="c-link-blocks__single__cta">
                            <i className="icon-internal"/>
                        </div>
                    </div>
                </a>

            )

        }



    }

    _click(){

        if (this.props.type === 'post'){

            if (this.props.link_post.post_type === 'ziektebeelden'){
                this.props.history.push(RouteEnum.Syndromes + '/' + this.props.link_post.ID)
            }else if (this.props.link_post.post_type === 'medicamenten'){
                this.props.history.push(RouteEnum.Medicaments + '/' + this.props.link_post.ID)
            }

        }else if (this.props.type === 'taxonomy'){
            this.props.history.push(RouteEnum.Subjects + '/' + this.props.link_taxonomy)
        }

    }

}

export default Link
