import React from 'react'
import Header from "../_components/Header";
import RouteEnum from "../../constants/RouteEnum";
import AccountAction from "../../stores/api/account/AccountAction";
import {connect} from "react-redux";

const mapStateToProps = (state, ownProps) => ({
    ...state.account
});

class AccountDeactivate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            checked: false,
        }
    }

    handleCheck() {
        this.setState({checked: !this.state.checked});
    }

    handleClick() {

        if (this.state.checked){

            this.props.dispatch(AccountAction.requestDeactivate())

        }

    }

    render() {

        return (

            <main>
                <Header history={this.props.history}/>
                <div className="o-container o-container--primary">

                    <header className="c-subject-header c-subject-header--detail">
                        <div className="c-subject-header--detail__text">
                            <a onClick={() => this._clickAccount()}><small> &lt; </small><span>Accountinstellingen</span></a>
                            <h1>Account deactiveren</h1>
                        </div>
                    </header>

                    <div className="c-account">
                        <div className="c-account__info">
                            <p>
                                Weet je zeker dat je je account wilt deactiveren? Het account is dan niet meer
                                toegankelijk. Het account wordt anoniem gearchiveerd en jouw persoonlijke gegevens zijn
                                niet meer terug te leiden naar dit account en haar activiteiten.
                            </p>
                        </div>
                        <label className="c-account__checkbox">
                            <input type="checkbox" onChange={() => this.handleCheck()} defaultChecked={this.state.checked}/>
                            <span>Ik weet zeker dat ik mijn account wil deactiveren. Ik begrijp ook dat mijn activiteit daarmee wordt verwijderd en ik geen toegang meer heb tot mijn account.</span>
                        </label>
                        <div className="c-account__cta">

                            <a onClick={() => this.handleClick()}  className="btn--pink btn--large"><span>Account deactiveren</span></a>

                            <a onClick={() => this._clickAccount()} className="btn--outline-green btn--large"><span>Annuleren</span></a>
                        </div>
                    </div>

                </div>
            </main>

        )

    }
    _clickAccount() {
        this.props.history.push(RouteEnum.Account)
    }
}

export default connect(mapStateToProps)(AccountDeactivate);
