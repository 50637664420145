import SearchAction from "./SearchAction";

export default class SearchReducer {

    static initialState = {

        search: {
            data: false,
            error: false
        }

    }

    static reducer(state = SearchReducer.initialState, action) {
        switch (action.type) {
            case SearchAction.REQUEST_SEARCH:
                return {
                    ...state,
                    search: {
                        error: false,
                        data: false
                    }
                }
            case SearchAction.REQUEST_SEARCH + '_FINISHED':
                return {
                    ...state,
                    search: {
                        error: action.payload.error,
                        data: action.payload.data
                    },
                };
            default:
                return state;

        }
    }

}
