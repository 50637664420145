import React from 'react'
import Header from "../_components/Header";
import RouteEnum from "../../constants/RouteEnum";
import {connect} from "react-redux";

const mapStateToProps = (state, ownProps) => ({
    ...state.account
});

class Account extends React.Component {

    render() {

        return (

            <main>
                <Header history={this.props.history}/>
                <div className="o-container o-container--primary">

                    <header className="c-subject-header">
                        <div className="c-subject-header--detail__text">
                            <h1>Accountinstellingen</h1>
                        </div>
                    </header>

                    <div className="c-account">
                        <h2>Persoonlijke gegevens</h2>
                        <div className="c-account__info">
                            <div className="c-account__info__single">
                                <small>Naam</small>
                                <span>{localStorage.getItem('username')}</span>
                            </div>
                            <div className="c-account__info__single">
                                <small>E-mailadres</small>
                                <span>{localStorage.getItem('email')}</span>
                            </div>
                        </div>
                        <div className="c-account__cta">
                            <a onClick={() => this._clickAdjust()} className="btn--green btn--large"><span>Mijn gegevens wijzigen</span></a>
                            <a onClick={() => this._clickPassword()} className="btn--outline-green btn--large"><span>Wachtwoord wijzigen</span></a>
                        </div>
                    </div>

                    <a onClick={() => this._clickDeActivate()} className="btn--underline btn--float-center-bottom">
                        <span>Account deactiveren</span>
                    </a>

                </div>
            </main>

        )

    }

    _clickAdjust() {
        this.props.history.push(RouteEnum.AccountAdjust)
    }

    _clickPassword() {
        this.props.history.push(RouteEnum.AccountPassword)
    }

    _clickDeActivate() {
        this.props.history.push(RouteEnum.AccountDeactivate)
    }

}

export default connect(mapStateToProps)(Account);
