import React from 'react';
import Info from "../../../../images/info-modal.svg";
import _Popup from "./_Popup";

class ButtonPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggled: false
        }

        this._toggle = this._toggle.bind(this)
    }

    render() {

        return (

            <div>
                <button className="c-link-blocks__single c-link-blocks__single--modal" onClick={this._toggle}>
                    <div className="c-link-blocks__single__header">
                        <div className="c-link-blocks__single__text">
                            <span>{this.props.titel}</span>
                            <small>{this.props.ondertekst}</small>
                        </div>
                        <div className="c-link-blocks__single__cta">
                            <img src={Info} alt="info"/>
                        </div>
                    </div>
                </button>
                <_Popup data={this.props.popup_button} toggle={this._toggle} active={this.state.toggled}/>
            </div>

        )

    }

    _toggle = () => {
        this.setState({
            toggled: !this.state.toggled
        })
    }

}

export default ButtonPopup


