import React from 'react';
import Close from "../../../../images/close.svg";
import Flex from "../index";

class _Popup extends React.Component {

    render() {

        const acfData = this.props.data;

        let modalClass = 'c-modal'

        if(this.props.active){
            modalClass += ' c-modal--active'
        }

        return (
            <div className={modalClass}>
                <div className="c-modal__bg" onClick={this.props.toggle}/>
                <button className="c-modal__close" onClick={this.props.toggle}>
                    <img src={Close} alt="close"/>
                </button>
                {!!acfData &&
                <div className="c-modal__content">
                    <div className="c-modal__body">
                        <div className="c-article">
                            {acfData.map((item) => (<Flex {...item} />))}
                        </div>
                    </div>
                </div>
                }
            </div>

        )

    }

}

export default _Popup
