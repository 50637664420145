import React from 'react'

class Video extends React.Component {

    render() {

        if (this.props.youtube_of_vimeo === "youtube") {

            if (this.props.video_id) {
                return (
                    <iframe width="100%" height="300" src={"https://www.youtube.com/embed/" + this.props.video_id}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                )
            } else {
                return null
            }

        } else {
            if (this.props.video_id) {
                return (
                    <iframe src={"https://player.vimeo.com/video/" + this.props.video_id} width="100%" height="300"
                            frameBorder="0"
                            allow="autoplay; fullscreen" allowFullScreen/>
                )
            } else {
                return null
            }
        }
    }
}

export default Video
