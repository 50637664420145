import React from 'react'

class SyndromeBron extends React.Component {

    render() {

        if (this.props.tekst_of_link === 'text') {
            return (
                <li>{this.props.tekst}</li>
            )
        } else {
            return (
                <li><a href={this.props.link_url} target="_blank">{this.props.link_tekst}</a><i
                    className="icon-external"/></li>
            )
        }
    }
}

export default SyndromeBron
