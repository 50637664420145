import ActionUtility from "../../../utilities/etc/ActionUtility";
import MedicamentEffect from "./MedicamentEffect";


export default class MedicamentAction {

    static REQUEST_SINGLE = 'MedicamentAction.REQUEST_SINGLE';
    static REQUEST_ALL = 'MedicamentAction.REQUEST_ALL';
    static REQUEST_SEARCH = 'MedicamentAction.REQUEST_SEARCH';

    static requestSingle(id) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, MedicamentAction.REQUEST_SINGLE, MedicamentEffect.requestSingle, id);
        }

    }

    static requestAll() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, MedicamentAction.REQUEST_ALL, MedicamentEffect.requestAll)
        }

    }

    static requestSearch(term) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, MedicamentAction.REQUEST_SEARCH, MedicamentEffect.requestSearch, term);
        }

    }
}
