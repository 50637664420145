import React from 'react'
import SearchItem from "./_components/SearchItem";
import Header from "../_components/Header";
import {connect} from "react-redux";
import SearchAction from "../../stores/api/search/SearchAction";
import sortAlphabetically from "../../utilities/etc/sortAlphabetically";

const mapStateToProps = (state, ownProps) => ({
    ...state.search.search
});

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.searching = this.searching.bind(this);
        this._clearInput = this._clearInput.bind(this)
    }

    state = {
        value: '',
        typingTimeout: 0,
        searching: false,
        searchResult: false,
        timer: null
    }

    componentDidMount() {
        this.searchInput.focus();
    }

    searching = (event) => {
        const self = this

        self.setState({
            searchResult: true
        });

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            value: event.target.value,
            typingTimeout: setTimeout(function () {
                self.searchQuery(self.state.value);
            }, 800),
        });

        if (self.state.timer) {
            clearTimeout(self.state.timer)
            self.state.timer = null
        }

        self.setState({
            timer: setTimeout(function () {
                console.log(self)
                console.log(self.props)
                if (self.props.data.syndromes || self.props.data.medicaments) {
                    self.setState({
                        searchResult: true
                    });
                } else {
                    self.setState({
                        searchResult: false
                    });
                }
            }, 3000)
        })


        if (event.target.value) {
                self.setState({
                    searching: true,
                })
        } else {
                self.setState({
                    searching: false
                })
        }


    }

    searchQuery = () => {
        this.props.dispatch(SearchAction.requestSearch(this.state.value))
    }

    _clearInput() {

        if (this.state.value) {
            this.setState({
                value: '',
                searching: false
            });
        } else {
            this.props.history.goBack()
        }
    }


    render() {


        let syndromeItems
        let medicamentItems

        if (this.state.searching) {
            if (this.props.data.syndromes) {
                syndromeItems = Object.values(this.props.data.syndromes)
                sortAlphabetically(syndromeItems)
            }
            if (this.props.data.medicaments) {
                medicamentItems = Object.values(this.props.data.medicaments)
                sortAlphabetically(medicamentItems)
            }
        }

        return (

            <main>
                <Header history={this.props.history}/>
                <div className="o-container o-container--primary">

                    <div className="c-search">

                        <div className="c-search-box">
                            <label htmlFor="searchInput">Zoek door het formularium</label>
                            <div className="c-search-box__input">
                                <input type="text" id="searchInput" value={this.state.value}
                                       onChange={this.searching} ref={inputEl => (this.searchInput = inputEl)}/>
                                <button onClick={this._clearInput}><i className="icon-cross"/></button>
                            </div>
                        </div>


                        {this.state.searching ? (

                            !!syndromeItems || medicamentItems ? (

                                <div className="c-link-blocks">

                                    {!!syndromeItems &&
                                    <div className="c-link-blocks__group">
                                        <div className="c-link-blocks__category">
                                            <span>Ziektebeelden</span>
                                            <div
                                                className="btn btn--number btn--number--white">{syndromeItems.length}</div>
                                        </div>

                                        {syndromeItems.map((data) => (
                                            <SearchItem history={this.props.history} {...data} />))}

                                    </div>
                                    }

                                    {!!medicamentItems &&
                                    <div className="c-link-blocks__group">
                                        <div className="c-link-blocks__category c-link-blocks__category--pink">
                                            <span>Medicamenten</span>
                                            <div
                                                className="btn btn--number btn--number--white">{medicamentItems.length}</div>
                                        </div>

                                        {medicamentItems.map((data) => (
                                            <SearchItem history={this.props.history} {...data} />))}

                                    </div>
                                    }
                                </div>

                            ) : (


                                <div>

                                    {!this.state.searchResult &&

                                    <div className="c-notification c-notification--message">
                                        <i className="icon-cross"/>
                                        <span>Helaas geen resultaten gevonden, probeer een andere zoekterm.</span>
                                    </div>

                                    }

                                </div>

                            )

                        ) : (

                            <div className="c-search__context">
                                <div className="c-search__context__figures">
                                    <div><i className="icon-heart"/></div>
                                    <div><i className="icon-medicine"/></div>
                                </div>
                                <span>Vind ziektebeelden en medicamenten</span>
                            </div>

                        )}


                    </div>

                </div>
            </main>

        )


    }


}

export default connect(mapStateToProps)(Search);
