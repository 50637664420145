import React from 'react'
import SubjectRelationItem from './_components/SubjectRelationItem'
import {connect} from "react-redux";
import SubjectAction from "../../stores/api/subject/SubjectAction";
import Header from "../_components/Header";
import Loadscreen from "../_components/Loadscreen";
import RouteEnum from "../../constants/RouteEnum";


const mapStateToProps = (state, ownProps) => ({
    ...state.subject.single
});

class SubjectDetail extends React.Component {

    init() {
        const id = this.props.match.params.id;
        this.props.dispatch(SubjectAction.requestSingle(id))
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init()
        }
    }

    render() {

        if (this.props.data) {

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header c-subject-header--detail">
                            <div className="c-subject-header--detail__text">
                                <a onClick={() => this._clickSubjects()}><small>&lt;</small><span>terug naar hoofdonderwerpen</span></a>
                                <h1>{this.props.data.name}</h1>
                                <small>{this.props.data.parent}</small>
                            </div>
                        </header>

                        <div className="c-link-blocks">


                            <div className="c-link-blocks__group">
                                <div className="c-link-blocks__category">
                                    <span>Gekoppelde ziektebeelden</span>
                                </div>

                                {this.props.data.syndrome ? (

                                    Object.values(this.props.data.syndrome).map((data) => (
                                        <SubjectRelationItem history={this.props.history} {...data} />))

                                ) : (

                                    <div className="c-notification c-notification--message">
                                        <i className="icon-info"/>
                                        <span>Dit subonderwerp heeft nog geen gekoppelde ziektebeelden.</span>
                                    </div>

                                )}

                            </div>


                            {!!this.props.data.related &&

                            <div className="c-link-blocks__group">
                                <div className="c-link-blocks__category">
                                    <span>Gerelateerde subonderwerpen</span>
                                </div>

                                {Object.values(this.props.data.related).map((data) => (
                                    <SubjectRelationItem history={this.props.history} {...data} />))}

                            </div>

                            }


                        </div>
                    </div>
                </main>

            )

        } else {

            return <Loadscreen/>

        }

    }

    _clickSubjects() {
        this.props.history.push(RouteEnum.Subjects)
    }

}

export default connect(mapStateToProps)(SubjectDetail);
