import React from 'react'
import {connect} from "react-redux";
import InfoAction from "../../stores/api/info/InfoAction";
import Flex from "../_components/Flex";
import RouteEnum from "../../constants/RouteEnum";
import Header from "../_components/Header";
import Loadscreen from "../_components/Loadscreen";


const mapStateToProps = (state, ownProps) => ({
    ...state.appInfo.single
});

class AppInfo extends React.Component {

    componentDidMount() {
        this.props.dispatch(InfoAction.requestSingle())
    }

    render() {

        if (this.props.data) {

            const data = this.props.data
            const acfData = this.props.data.acfData

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header c-subject-header--detail">
                            <div className="c-subject-header--detail__text">
                                <a onClick={() => this._clickSyndrome()}><small>&lt;</small><span>Dashboard</span></a>
                                <h1>{data.title}</h1>
                            </div>
                        </header>

                        <div className="c-detail">

                            {!!acfData &&

                            <div className="c-detail__primary">
                                <article className="c-article">
                                    {acfData.map((item) => (<Flex history={this.props.history} {...item} />))}
                                </article>
                            </div>

                            }

                        </div>
                    </div>
                </main>

            )

        } else {

            return <Loadscreen/>

        }


    }

    _clickSyndrome() {
        this.props.history.push(RouteEnum.Dashboard)
    }

}

export default connect(mapStateToProps)(AppInfo);
