import React from 'react'
import Accordion from "./_components/Accordion";
import Button from "./_components/Button";
import Divider from "./_components/Divider";
import DownloadBtn from "./_components/DownloadBtn";
import Image from "./_components/Image";
import Link from "./_components/Link";
import OlList from "./_components/OlList";
import UlList from "./_components/UlList";
import Video from "./_components/Video";
import WhiteSpace from "./_components/WhiteSpace";
import Title from "./_components/Title";
import TextArea from "./_components/TextArea";
import Span from "./_components/Span";
import ImagePopup from "./_components/ImagePopup";
import ButtonPopup from "./_components/ButtonPopup";


class Flex extends React.Component {

    render() {
        switch (this.props.acf_fc_layout) {
            case('accordion'):
                return <Accordion {...this.props} />
            case('knop'):
                return <Button history={this.props.history} {...this.props} />
            case('knop_met_popup'):
                return <ButtonPopup {...this.props} />
            case('scheidingslijn'):
                return <Divider {...this.props} />
            case('download_knop'):
                return <DownloadBtn {...this.props} />
            case('afbeelding'):
                return <Image {...this.props} />
            case('afbeelding_met_popup'):
                return <ImagePopup {...this.props} />
            case('link'):
                return <Link history={this.props.history} {...this.props} />
            case('geordende_lijst'):
                return <OlList {...this.props} />
            case('ongeordende_lijst'):
                return <UlList {...this.props} />
            case('video'):
                return <Video {...this.props} />
            case('witruimte'):
                return <WhiteSpace {...this.props} />
            case('koptitel'):
                return <Title {...this.props} />
            case('tekstveld'):
                return <TextArea {...this.props} />
            case('tekst_zonder_marges'):
                return <Span {...this.props} />
            default:
                return false

        }
    }
}

export default Flex
