import React from 'react'
import SubjectSubItem from "./SubjectSubItem";

class SubjectMainItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggled: false
        }
    }

    render() {

        let accordionClass = 'c-link-blocks__single c-link-blocks__single-dropdown'

        if(this.state.toggled){
            accordionClass += ' c-link-blocks__single--active'
        }

        return (

            <div className={accordionClass}>
                <div className="c-link-blocks__single__header" onClick={() => this._toggle()}>
                    <div className="c-link-blocks__single__text">
                        <span>{this.props.title}</span>
                    </div>
                    <div className="c-link-blocks__single__cta">
                        <i className="icon-arrow-right"/>
                    </div>
                </div>

                {!!this.props.children &&

                <div className="c-link-blocks__single__body">

                    {Object.values(this.props.children).map((data) => (
                        <SubjectSubItem history={this.props.history} {...data} />))}

                </div>

                }

            </div>


        )

    }

    _toggle() {

        this.setState({
            toggled: !this.state.toggled
        })

    }

}

export default SubjectMainItem
