import React from 'react'
import {Route, Switch, HashRouter} from 'react-router-dom'
import RouteEnum from "../constants/RouteEnum";
import SubjectOverview from "./subjects/SubjectOverview";
import SubjectDetail from "./subjects/SubjectDetail";
import SyndromeOverview from "./syndromes/SyndromeOverview";
import SyndromeDetail from "./syndromes/SyndromeDetail";
import Search from "./search/Search";
import MedicamentOverview from "./medicaments/MedicamentOverview";
import MedicamentDetail from "./medicaments/MedicamentDetail";
import FeedbackOverview from "./feedback/FeedbackOverview";
import FeedbackDetail from "./feedback/FeedbackDetail";
import AccountDeactivate from "./account/AccountDeactivate";
import Account from "./account/Account";
import AccountAdjust from "./account/AccountAdjust";
import Dashboard from "./dashboard/Dashboard";
import MedicamentVersion from "./medicaments/MedicamentVersion";
import SyndromeVersion from "./syndromes/SyndromeVersion";
import AccountPassword from "./account/AccountPassword";
import AccountAction from "../stores/api/account/AccountAction";
import AppInfo from "./appInfo/AppInfo";
import {connect} from "react-redux";

const mapStateToProps = (state, ownProps) => ({
    ...state
});

class LoggedIn extends React.Component {

    componentDidMount() {
        const currentTime = new Date().getTime()
        if(currentTime - localStorage.getItem('tokenDate') > 1000 * 60 * 60 * 24){
            this.props.dispatch(AccountAction.requestSignOut())
        }
    }

    render() {

        const props = this.props

        return (

                <HashRouter history={props.history} basename={''}>
                    <Switch>
                        <Route
                            exact
                            path={RouteEnum.Subjects}
                            history={props.history}
                            component={SubjectOverview}
                        />
                        <Route
                            exact
                            path={RouteEnum.Subjects + '/:id'}
                            history={props.history}
                            component={SubjectDetail}
                        />
                        <Route
                            exact
                            path={RouteEnum.Syndromes}
                            history={props.history}
                            component={SyndromeOverview}
                        />
                        <Route
                            exact
                            path={RouteEnum.Syndromes + '/:id'}
                            history={props.history}
                            component={SyndromeDetail}
                        />
                        <Route
                            exact
                            path={RouteEnum.SyndromesVersions + '/:id'}
                            history={props.history}
                            component={SyndromeVersion}
                        />
                        <Route
                            exact
                            path={RouteEnum.AppInfo}
                            history={props.history}
                            component={AppInfo}
                        />
                        <Route
                            exact
                            path={RouteEnum.Medicaments}
                            history={props.history}
                            component={MedicamentOverview}
                        />
                        <Route
                            exact
                            path={RouteEnum.Medicaments + '/:id'}
                            history={props.history}
                            component={MedicamentDetail}
                        />
                        <Route
                            exact
                            path={RouteEnum.MedicamentsVersion + '/:id'}
                            history={props.history}
                            component={MedicamentVersion}
                        />
                        <Route
                            exact
                            path={RouteEnum.Feedback}
                            history={props.history}
                            component={FeedbackOverview}
                        />
                        <Route
                            exact
                            path={RouteEnum.Feedback + '/:id'}
                            history={props.history}
                            component={FeedbackDetail}
                        />
                        <Route
                            exact
                            path={RouteEnum.Search}
                            history={props.history}
                            component={Search}
                        />
                        <Route
                            exact
                            path={RouteEnum.Dashboard}
                            history={props.history}
                            component={Dashboard}
                        />
                        <Route
                            exact
                            path={RouteEnum.Account}
                            history={props.history}
                            component={Account}
                        />
                        <Route
                            exact
                            path={RouteEnum.AccountAdjust}
                            history={props.history}
                            component={AccountAdjust}
                        />
                        <Route
                            exact
                            path={RouteEnum.AccountPassword}
                            history={props.history}
                            component={AccountPassword}
                        />
                        <Route
                            exact
                            path={RouteEnum.AccountDeactivate}
                            history={props.history}
                            component={AccountDeactivate}
                        />
                        <Route component={Dashboard}/>
                    </Switch>
                </HashRouter>
        )

    }

}

export default connect(mapStateToProps)(LoggedIn);
