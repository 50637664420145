import AccountAction from "./AccountAction";

export default class AccountReducer {

    static initialState = {

        signIn: {
            data: {
                token: localStorage.getItem('token'),
                email: localStorage.getItem('email'),
                username: localStorage.getItem('username'),
                id: localStorage.getItem('id'),
                tokenDate: localStorage.getItem('tokenDate')
            },
            error: false
        },

        signOut: false,

        create: {
            data: false,
            error: false
        },

        register: {
            data: false,
            error: false
        },

        change: {
            data: false,
            error: false
        },

        changePassword:{
            data: false,
            error: false
        },

        updatePassword: {
            data: false,
            error: false
        },

        forgotPassword: {
            error: false,
            data: false
        }


    }

    static reducer(state = AccountReducer.initialState, action) {
        switch (action.type) {
            case AccountAction.REQUEST_SIGNIN:
                return {
                    ...state,
                    signIn: {
                        error: false,
                        data: false
                    },
                };
            case AccountAction.REQUEST_SIGNIN + '_FINISHED':
                return {
                    ...state,
                    signIn: {
                        error: action.payload.error,
                        data: action.payload.data
                    },
                };
            case AccountAction.REQUEST_SIGNOUT:
                return {
                    ...state,
                    signIn: {
                        error: false,
                        data: false
                    },
                    signOut: false,
                };
            case AccountAction.REQUEST_SIGNOUT + '_FINISHED':
                return {
                    ...state,
                    signIn: {
                        error: false,
                        data: false
                    },
                    signOut: true,
                };
            case AccountAction.REQUEST_DEACTIVATE:
                return {
                    ...state,
                    signIn: {
                        error: false,
                        data: false
                    }
                };
            case AccountAction.REQUEST_DEACTIVATE + '_FINISHED':
                return {
                    ...state,
                    signIn: {
                        error: false,
                        data: false
                    }
                };
            case AccountAction.REQUEST_CREATE:
                return {
                    ...state,
                    create: {
                        error: false,
                        data: false
                    }
                };
            case AccountAction.REQUEST_CREATE + '_FINISHED':
                return {
                    ...state,
                    create: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                };
            case AccountAction.REQUEST_REGISTER:
                return {
                    ...state,
                    register: {
                        error: false,
                        data: false
                    }
                };
            case AccountAction.REQUEST_REGISTER + '_FINISHED':
                return {
                    ...state,
                    register: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                }
            case AccountAction.REQUEST_CHANGE:
                return {
                    ...state,
                    change: {
                        error: false,
                        data: false
                    }
                };
            case AccountAction.REQUEST_CHANGE + '_FINISHED':
                return {
                    ...state,
                    change: {
                        error: true,
                        data: true
                    }
                };
            case AccountAction.REQUEST_CHANGE_PASSWORD:
                return {
                    ...state,
                    changePassword: {
                        error: false,
                        data: false
                    }
                };
            case AccountAction.REQUEST_CHANGE_PASSWORD + '_FINISHED':
                return {
                    ...state,
                    changePassword: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                };
            case AccountAction.REQUEST_UPDATE_PASSWORD:
                return {
                    ...state,
                    updatePassword: {
                        error: false,
                        data: false
                    }
                };
            case AccountAction.REQUEST_UPDATE_PASSWORD + '_FINISHED':
                return {
                    ...state,
                    updatePassword: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                };
            case AccountAction.REQUEST_FORGOT_PASSWORD:
                return {
                    ...state,
                    forgotPassword: {
                        error: false,
                        data: false
                    }
                };
            case AccountAction.REQUEST_FORGOT_PASSWORD + '_FINISHED':
                return {
                    ...state,
                    forgotPassword: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                };
            default:
                return state;

        }
    }

}
