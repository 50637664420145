import React from 'react'
import DashboardMainItem from "./_components/DashboardMainItem";
import Header from "../_components/Header";
import {connect} from "react-redux";
import SubjectAction from "../../stores/api/subject/SubjectAction";
import Loadscreen from "../_components/Loadscreen";
import RouteEnum from "../../constants/RouteEnum";
import sortAlphabetically from "../../utilities/etc/sortAlphabetically";

const mapStateToProps = (state, ownProps) => ({
    ...state.subject,
});

class Dashboard extends React.Component {

    componentDidMount() {
        this.props.dispatch(SubjectAction.requestAll())
    }

    render() {


        if (this.props.all.data) {

            const items = Object.values(this.props.all.data)
            sortAlphabetically(items)

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">
                        <header className="c-subject-header c-subject-header--search">
                            <h1><i className="icon-dashboard"/> Welkom, {localStorage.getItem('username')}</h1>
                            <div className="c-search-box">
                                <div className="c-search-box__input">
                                    <input type="text" placeholder="Zoek door het formularium" onClick={() => this._clickSearch()}/>
                                    <div>
                                        <i className="icon-search"/>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <div className="c-link-blocks">
                            <div className="c-link-blocks__group">
                                <div className="c-link-blocks__category">
                                    <span>Hoofd- en subonderwerpen</span>
                                </div>

                                {

                                    items.map((data) => (
                                        <DashboardMainItem history={this.props.history} {...data} />))

                                }

                            </div>
                        </div>

                    </div>
                </main>

            )

        } else {

            return <Loadscreen/>

        }

    }

    _clickSearch(){
        this.props.history.push(RouteEnum.Search)
    }

}

export default connect(mapStateToProps)(Dashboard);
