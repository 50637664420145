import React from 'react'
import RouteEnum from "../../../constants/RouteEnum";

class SubjectRelationItem extends React.Component {

    render() {

        return (

            <a onClick={() => this._clickRelated()}  className="c-link-blocks__single">
                <div className="c-link-blocks__single__header">
                    <div className="c-link-blocks__single__text">
                        <span>{this.props.name}</span>
                        {!!this.props.subject &&
                        <small>{!!this.props.parentSubject.name &&<i>{this.props.parentSubject.name} ></i>} {this.props.subject.name}</small>
                        }
                    </div>
                    <div className="c-link-blocks__single__cta">
                        <i className="icon-arrow-right"/>
                    </div>
                </div>
            </a>


        )

    }

    _clickRelated(){
        const id = this.props.id;

        if (this.props.type === 'term'){
            this.props.history.push(RouteEnum.Subjects + '/' + id)
        }else{
            this.props.history.push(RouteEnum.Syndromes + '/' + id)
        }
    }

}

export default SubjectRelationItem
