import React from 'react'


class DownloadBtn extends React.Component {

    render() {

        if (this.props.bestand.url){

            return (

                <a href={this.props.bestand.url} target="_blank" className="c-link-blocks__single">
                    <div className="c-link-blocks__single__header">
                        <div className="c-link-blocks__single__text">
                            <span>{this.props.tekst}</span>
                            <small>{this.props.ondertekst} | {this.props.bestand.subtype} ({this._bytesToSize(this.props.bestand.filesize)})</small>
                        </div>
                        <div className="c-link-blocks__single__cta">
                            <i className="icon-download"/>
                        </div>
                    </div>
                </a>

            )
        }else{
            return null
        }
    }

    _bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
}

export default DownloadBtn



