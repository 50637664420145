import React from 'react'
import {connect} from "react-redux";
import logo from "../../images/ZBVO-Formularium-logo.svg"
import AccountAction from "../../stores/api/account/AccountAction";
import RouteEnum from "../../constants/RouteEnum";

const mapStateToProps = (state, ownProps) => ({
    ...state.account
});

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            email: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault()
            const email = {
                email: this.state.email,
            }
            this.props.dispatch(AccountAction.requestForgotPassword(email))
    }

    render() {

        let errorPopupStyle = false;
        let infoPopupStyle = false;
        let responseCode = false;
        let responseMessage = false;

        if (this.props.forgotPassword.data) {
            responseCode = this.props.forgotPassword.data.code;
            responseMessage = this.props.forgotPassword.data.message;
        }

        if (responseCode === 500) {
            errorPopupStyle = true
        }

        if (responseCode === 200) {
            infoPopupStyle = true
        }

        const {email} = this.state

        let emailLabelClass = 'c-auth-label'

        if(this.state.email){
            emailLabelClass += ' c-auth-label--active'
        }

        return (

            <main>
                <div className="c-auth">
                    <img src={logo} alt="logo" className="c-auth__logo"/>


                    <div className="c-auth__text">
                        <h1>Wachtwoord herstellen</h1>
                        <p>Gelieve uw e-mailadres in te vullen. U krijgt van ons een e-mail met instructies om uw wachtwoord te veranderen.</p>
                    </div>
                    <form onSubmit={this.handleSubmit} className="c-auth__form">
                        <div className="c-auth__form__group">
                            <input type="email" id="email" name='email' value={email} required={true}
                                   onChange={this.handleChange}/>
                            <label className={emailLabelClass} htmlFor="text">E-maildres</label>
                        </div>
                        <button className="c-auth__form__button btn--large btn--green">
                            <span>Wachtwoord herstellen</span>
                        </button>

                        {!!errorPopupStyle &&
                        <div className="c-notification c-notification--error">
                            <i className="icon-info"/>
                            <span>{responseMessage}</span>
                        </div>
                        }

                        {!!infoPopupStyle &&
                        <div className="c-notification c-notification--message">
                            <i className="icon-info"/>
                            <span>{responseMessage}</span>
                        </div>
                        }

                        <a onClick={() => this._clickLogin()} className="c-auth__form__link">Inloggen</a>

                    </form>

                </div>
            </main>

        )
    }

    _clickLogin() {
        this.props.history.push(RouteEnum.Login)
    }

}

export default connect(mapStateToProps)(ForgotPassword);
