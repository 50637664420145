const SortItems = (Data) => {

    let formattedItems = {}


    for (const key in Data) {

        let item = Data[key];

        const itemFirstCharacter = item.title.charAt(0).toLocaleUpperCase()

        if(!(itemFirstCharacter in formattedItems)) {
            formattedItems[itemFirstCharacter] = []
        }

        formattedItems[itemFirstCharacter].push(item)
        sortAlphabetically(formattedItems[itemFirstCharacter])

    }

    formattedItems = sortObj(formattedItems)


    return formattedItems

}

const sortObj = (obj) => {
    return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}

const sortAlphabetically = (objArray) => {
    objArray.sort(function(a, b) {
        var textA = a.title.toUpperCase();
        var textB = b.title.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
}


export default SortItems
