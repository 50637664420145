import ActionUtility from "../../../utilities/etc/ActionUtility";
import SyndromeEffect from "./SyndromeEffect";



export default class SyndromeAction {

    static REQUEST_SINGLE = 'SyndromeAction.REQUEST_SINGLE';
    static REQUEST_ALL = 'SyndromeAction.REQUEST_ALL';
    static REQUEST_SEARCH = 'SyndromeAction.REQUEST_SEARCH';

    static requestSingle(id) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, SyndromeAction.REQUEST_SINGLE, SyndromeEffect.requestSingle, id);
        }

    }

    static requestAll() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, SyndromeAction.REQUEST_ALL, SyndromeEffect.requestAll)
        }

    }

    static requestSearch(term) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, SyndromeAction.REQUEST_SEARCH, SyndromeEffect.requestSearch, term);
        }

    }
}
