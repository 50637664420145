import React from 'react'
import RouteEnum from "../../../constants/RouteEnum";

class MedicamentItem extends React.Component{

    render() {

        return(

            <a onClick={() => this._clickRelated()} className="c-link-blocks__single">
                <div className="c-link-blocks__single__header">
                    <div className="c-link-blocks__single__text">
                        <span>{this.props.title}</span>
                    </div>
                    <div className="c-link-blocks__single__cta">
                        <i className="icon-arrow-right"/>
                    </div>
                </div>
            </a>

        )

    }

    _clickRelated(){
        const id = this.props.id;
        this.props.history.push(RouteEnum.Medicaments + '/' + id)
    }

}

export default MedicamentItem
