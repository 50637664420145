import React from 'react'
import RouteEnum from "../../../constants/RouteEnum";

class FeedbackItem extends React.Component {

    render() {

        let chatCount = 0
        if (this.props.acfData.opmerkingen) {
            chatCount = this.props.acfData.opmerkingen.length
        }
        let statusClass = 'icon-chat'
        if (this.props.status) {
            statusClass += ' icon-chat--new'
        }

        return (

            <a onClick={() => this._click()} className="c-link-blocks__single c-link-blocks__single--locked">
                <div className="c-link-blocks__single__header">
                    <div className="c-link-blocks__single__text">
                        <span>{this.props.title}</span>
                        {!!this.props.subject &&
                        <small>{!!this.props.parentSubject &&
                        <i>{this.props.parentSubject.name} ></i>} {this.props.subject.name}</small>
                        }
                    </div>
                    <div className="c-link-blocks__single__count">
                        {!!this.props.acfData.discussie_gesloten &&
                        <i className="icon-lock lock"/>
                        }
                        <i className={statusClass}/>
                        <div className="btn btn--number">{chatCount}</div>
                    </div>
                </div>
            </a>

        )

    }

    _click() {
        const id = this.props.id;
        this.props.history.push(RouteEnum.Feedback + '/' + id)
    }

}

export default FeedbackItem
