const autoTargetBlank = (string) => {

    const div = document.createElement("div");
    div.innerHTML = string;
    const links = div.querySelectorAll("a");


    links.forEach((link) => {

        if(!link.href.includes('backoffice-zbvo')){

            string = string.replace(link.href+'"', link.href + '" target="_blank"')

        }

    })


    return string

}

export default autoTargetBlank
