import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import AccountReducer from './api/account/AccountReducer';
import FeedbackReducer from './api/feedback/FeedbackReducer';
import MedicamentReducer from "./api/medicament/MedicamentReducer";
import SubjectReducer from "./api/subject/SubjectReducer";
import SyndromeReducer from "./api/syndrome/SyndromeReducer";
import SearchReducer from "./api/search/SearchReducer";
import InfoReducer from "./api/info/InfoReducer";

export default (history) => {

    const reducerMap = {
        router: connectRouter(history),
        account: AccountReducer.reducer,
        feedback: FeedbackReducer.reducer,
        medicament: MedicamentReducer.reducer,
        appInfo: InfoReducer.reducer,
        subject: SubjectReducer.reducer,
        syndrome: SyndromeReducer.reducer,
        search: SearchReducer.reducer,
    };

    return combineReducers(reducerMap);
};
