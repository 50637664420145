import React from 'react'
import {connect} from "react-redux";
import AccountAction from "../../stores/api/account/AccountAction";
import Header from "../_components/Header";
import RouteEnum from "../../constants/RouteEnum";

const mapStateToProps = (state, ownProps) => ({
    ...state.account
});

class AccountAdjust extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            firstname: localStorage.getItem('firstName'),
            lastname: localStorage.getItem('lastName'),
            email: localStorage.getItem('email')
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault()

        let firstName = localStorage.getItem('firstName')
        if(this.state.firstname){
            firstName = this.state.firstname
        }

        let lastName = localStorage.getItem('lastName')
        if(this.state.lastname){
            lastName = this.state.lastname
        }

        let email = localStorage.getItem('email')
        if(this.state.email){
            email = this.state.email
        }

        const changeData = {
            firstname: firstName,
            lastname: lastName,
            email: email
        }

        this.props.dispatch(AccountAction.requestChange(changeData)).then(() => {
            this.props.history.push(RouteEnum.Account)
        })

    }


    render() {

        const {firstname, lastname, email} = this.state

        let firstNameLabelClass = 'c-auth-label'
        let lastNameLabelClass = 'c-auth-label'
        let emailLabelClass = 'c-auth-label'

        if(this.state.firstname){
            firstNameLabelClass += ' c-auth-label--active'
        }

        if(this.state.lastname){
            lastNameLabelClass += ' c-auth-label--active'
        }

        if(this.state.email){
            emailLabelClass += ' c-auth-label--active'
        }

        return (

            <main>
                <Header history={this.props.history}/>

                <div className="o-container o-container--primary">

                    <header className="c-subject-header c-subject-header--detail">
                        <div className="c-subject-header--detail__text">
                            <a onClick={() => this._clickAccount()}><small> &lt; </small><span>Accountinstellingen</span></a>
                            <h1>Mijn gegevens wijzigen</h1>
                        </div>
                    </header>

                    <form onSubmit={this.handleSubmit} className="c-auth__form">
                        <div className="c-auth__form__group">
                            <input type="text" id="firstname" name='firstname' value={firstname}
                                   onChange={this.handleChange}/>
                            <label className={firstNameLabelClass} htmlFor="text">Voornaam</label>
                        </div>
                        <div className="c-auth__form__group">
                            <input type="text" id="lastname" name='lastname' value={lastname}
                                   onChange={this.handleChange}/>
                            <label className={lastNameLabelClass} htmlFor="text">Achternaam</label>
                        </div>
                        <div className="c-auth__form__group">
                            <input type="email" id="email" name='email' value={email}
                                   onChange={this.handleChange}/>
                            <label className={emailLabelClass} htmlFor="email">Email</label>
                        </div>
                        <button className="c-auth__form__button btn--large btn--green">
                            <span>Wijzigingen opslaan</span>
                        </button>
                    </form>

                </div>
            </main>

        )
    }

    _clickAccount() {
        this.props.history.push(RouteEnum.Account)
    }
}

export default connect(mapStateToProps)(AccountAdjust);

