import React from 'react'


class Title extends React.Component {

    render() {

        if (this.props.titel){
            return <h2>{this.props.titel}</h2>
        }else{
            return null
        }

    }

}

export default Title
