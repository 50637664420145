import React from 'react'

class Span extends React.Component{

    render() {

        if (this.props.tekst){
            return(
                <span>{this.props.tekst}</span>
            )
        }else {
            return null
        }

    }

}

export default Span
