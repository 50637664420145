import React from 'react'
import RouteEnum from "../../constants/RouteEnum";
import AccountAction from "../../stores/api/account/AccountAction";
import {connect} from "react-redux";
import FeedbackAction from "../../stores/api/feedback/FeedbackAction";
import logo from "../../images/ZBVO-Formularium-logo-white.svg"

const mapStateToProps = (state, ownProps) => ({
    ...state.account,
    ...state.feedback.all
});

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggled: true
        }
    }

    componentDidMount() {
        if (this.props.history.location.pathname !== RouteEnum.Feedback) {
            this.props.dispatch(FeedbackAction.requestAll())
        }
    }

    totalChatCount() {

        let syndromes = 0
        let medicaments = 0

        if (this.props.data.syndrome) {

            if (this.props.data.syndrome.new) {
                syndromes += Object.keys(this.props.data.syndrome.new).length
            }

        }

        if (this.props.data.medicament) {

            if (this.props.data.medicament.new) {
                medicaments += Object.keys(this.props.data.medicament.new).length
            }

        }

        return syndromes + medicaments

    }

    render() {

        let feedbackCounter = null;

        if (this.totalChatCount() > 0){
            feedbackCounter = <div className="btn--number btn--number--green">{this.totalChatCount()}</div>
        }

        return (

            <div className="c-menu">
                <header>
                    <a onClick={() => this._clickDashboard()}>
                        <img src={logo} alt="logo"/>
                    </a>
                    <button onClick={this.props.toggle}><i className="icon-cross"/></button>
                </header>
                <nav className="c-menu__primary-nav">
                    <ul className="u-reset-ul">
                        <li><a onClick={() => this._clickDashboard()}> <i className="icon-dashboard"/> Dashboard</a>
                        </li>
                        <li><a onClick={() => this._clickSubjects()}> <i className="icon-book"/> Hoofd- en
                            subonderwerpen</a></li>
                        <li><a onClick={() => this._clickSyndromes()}> <i className="icon-heart"/> Ziektebeelden</a>
                        </li>
                        <li><a onClick={() => this._clickMedicaments()}> <i className="icon-medicine"/> Medicamenten</a>
                        </li>
                    </ul>
                </nav>
                <nav className="c-menu__nav">
                    <ul className="u-reset-ul">
                        <li><a onClick={() => this._clickFeedback()}>Mijn feedback {feedbackCounter}</a></li>
                        <li><a onClick={() => this._clickAccount()}>Accountinstellingen</a></li>
                        <li><a onClick={() => this._clickAppInfo()}>Over het Formularium</a></li>
                        <li><a onClick={() => this._logOut()}>Uitloggen</a></li>
                    </ul>
                </nav>
            </div>

        )

    }

    _logOut() {
        this.props.dispatch(AccountAction.requestSignOut())
    }

    _clickAccount() {
        this.props.history.push(RouteEnum.Account)
    }

    _clickAppInfo() {
        this.props.history.push(RouteEnum.AppInfo)
    }

    _clickDashboard() {
        this.props.history.push(RouteEnum.Dashboard)
    }

    _clickSubjects() {
        this.props.history.push(RouteEnum.Subjects)
    }

    _clickSyndromes() {
        this.props.history.push(RouteEnum.Syndromes)
    }

    _clickMedicaments() {
        this.props.history.push(RouteEnum.Medicaments)
    }

    _clickFeedback() {
        this.props.history.push(RouteEnum.Feedback)
    }

}

export default connect(mapStateToProps)(Menu);
