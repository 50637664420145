const RouteEnum = {
    Home: process.env.PUBLIC_URL + '/',
    Login: process.env.PUBLIC_URL + '/login',
    Register: process.env.PUBLIC_URL + '/register',
    RegisterPassword: process.env.PUBLIC_URL + '/register-password',
    ForgotPassword: process.env.PUBLIC_URL + '/forgot-password',
    ResetPassword: process.env.PUBLIC_URL + '/reset-password',
    Dashboard: process.env.PUBLIC_URL + '/dashboard',
    AppInfo: process.env.PUBLIC_URL + '/info',
    Search: process.env.PUBLIC_URL + '/search',
    Subjects: process.env.PUBLIC_URL + '/subjects',
    Syndromes: process.env.PUBLIC_URL + '/syndromes',
    SyndromesVersions: process.env.PUBLIC_URL + '/syndromes/versions',
    Medicaments: process.env.PUBLIC_URL + '/medicaments',
    MedicamentsVersion: process.env.PUBLIC_URL + '/medicaments/versions',
    Feedback: process.env.PUBLIC_URL + '/feedback',
    Account: process.env.PUBLIC_URL + '/account',
    AccountAdjust: process.env.PUBLIC_URL + '/account/adjust',
    AccountPassword: process.env.PUBLIC_URL + '/account/password',
    AccountDeactivate: process.env.PUBLIC_URL + '/account/deactivate',
}

export default RouteEnum;
