import SyndromeAction from "./SyndromeAction";


export default class SyndromeReducer {

    static initialState = {

        single: {
            data: false,
            error: false
        },

        all: {
            data: false,
            error: false
        },

        search: {
            data: false,
            error: false
        }

    }

    static reducer(state = SyndromeReducer.initialState, action) {
        switch (action.type) {
            case SyndromeAction.REQUEST_SINGLE:
                return {
                    ...state,
                    single: {
                        error: false,
                        data: false
                    },
                };
            case SyndromeAction.REQUEST_SINGLE + '_FINISHED':
                return {
                    ...state,
                    single: {
                        error: action.payload.error,
                        data: action.payload.data
                    },
                };
            case SyndromeAction.REQUEST_ALL:
                return {
                    ...state,
                    all: {
                        error: false,
                        data: false
                    }
                }
            case SyndromeAction.REQUEST_ALL + '_FINISHED':
                return {
                    ...state,
                    all: {
                        error: action.payload.error,
                        data: action.payload.data
                    },
                };
            case SyndromeAction.REQUEST_SEARCH:
                return {
                    ...state,
                    search: {
                        error: false,
                        data: false
                    }
                }
            case SyndromeAction.REQUEST_SEARCH + '_FINISHED':
                return {
                    ...state,
                    search: {
                        error: action.payload.error,
                        data: action.payload.data
                    },
                };
            default:
                return state;

        }
    }

}
