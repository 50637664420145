import React from 'react'
import {connect} from "react-redux";
import logo from "../../images/ZBVO-Formularium-logo.svg"
import AccountAction from "../../stores/api/account/AccountAction";
import RouteEnum from "../../constants/RouteEnum";
import Close from "../../images/close.svg";

const mapStateToProps = (state, ownProps) => ({
    ...state.account
});

class Register extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            firstname: '',
            insertion: '',
            lastname: '',
            email: '',
            agree: false,
            modalStatus: false
        }

        this.props.dispatch(AccountAction.requestRegister())

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault()

        const createData = {
            firstname: this.state.firstname,
            insertion: this.state.insertion,
            lastname: this.state.lastname,
            email: this.state.email
        }

        this.props.dispatch(AccountAction.requestCreate(createData))

    }

    render() {

        let errorPopupStyle = false;
        let infoPopupStyle = false;
        let responseCode = false;
        let responseMessage = false;

        if (this.props.create.data) {
            responseCode = this.props.create.data.code;
            responseMessage = this.props.create.data.message;
        }

        if (responseCode === 503) {
            errorPopupStyle = true
        }

        if (responseCode === 302 || responseCode === 200) {
            infoPopupStyle = true
        }

        const {firstname, insertion, lastname, email} = this.state

        let firstNameClass = 'c-auth-label'
        let insertionClass = 'c-auth-label'
        let lastNameClass = 'c-auth-label'
        let emailClass = 'c-auth-label'

        if (this.state.firstname) {
            firstNameClass += ' c-auth-label--active'
        }

        if (this.state.insertion) {
            insertionClass += ' c-auth-label--active'
        }

        if (this.state.lastname) {
            lastNameClass += ' c-auth-label--active'
        }

        if (this.state.email) {
            emailClass += ' c-auth-label--active'
        }

        const checkboxHandler = () => {
            this.setState({
                agree: !this.state.agree
            });
        }


        const registerTerms = this.props.register.data;

        let modalClass = 'c-modal'

        const termsHandler = () => {
            this.setState({
                modalStatus: !this.state.modalStatus
            });
        }

        if (this.state.modalStatus) {
            modalClass += ' c-modal--active'
        }

        return (

            <main>
                <div className="c-auth">
                    <img src={logo} alt="logo" className="c-auth__logo"/>


                    <div className="c-auth__text">
                        <h1>Inloggen</h1>
                        <p>Vul onderstaande gegevens in om een account aan te vragen voor het ZBVO Formularium.</p>
                    </div>
                    <form onSubmit={this.handleSubmit} className="c-auth__form">
                        <div className="c-auth__form__group">
                            <input type="text" id="firstname" name='firstname' value={firstname} required={true}
                                   onChange={this.handleChange}/>
                            <label className={firstNameClass} htmlFor="text">Voornaam</label>
                        </div>
                        <div className="c-auth__form__group">
                            <input type="text" id="insertion" name='insertion' value={insertion}
                                   onChange={this.handleChange}/>
                            <label className={insertionClass} htmlFor="text">Tussenvoegsel</label>
                        </div>
                        <div className="c-auth__form__group">
                            <input type="text" id="lastname" name='lastname' value={lastname} required={true}
                                   onChange={this.handleChange}/>
                            <label className={lastNameClass} htmlFor="text">Achternaam</label>
                        </div>
                        <div className="c-auth__form__group">
                            <input type="email" id="email" name='email' value={email} required={true}
                                   onChange={this.handleChange}/>
                            <label className={emailClass} htmlFor="email">E-mailadres</label>
                        </div>
                        <div className="c-auth__form__group c-auth-check">
                            <input type="checkbox" onChange={checkboxHandler}/>
                            <label onClick={termsHandler}>Ik ga akkoord met de gebruikersoorwaarden</label>
                        </div>
                        <button className="c-auth__form__button btn--large btn--green" disabled={!this.state.agree}>
                            <span>Wachtwoord herstellen</span>
                        </button>

                        {!!errorPopupStyle &&
                        <div className="c-notification c-notification--error">
                            <i className="icon-info"/>
                            <span>{responseMessage}</span>
                        </div>
                        }

                        {!!infoPopupStyle &&
                        <div className="c-notification c-notification--message">
                            <i className="icon-info"/>
                            <span>{responseMessage}</span>
                        </div>
                        }

                    </form>

                    <a onClick={() => this._clickLogin()} className="btn--underline btn--float-center-bottom">
                        <span>Inloggen</span>
                    </a>


                    <div className={modalClass}>
                        <div className="c-modal__bg" onClick={termsHandler}/>
                        <button className="c-modal__close" onClick={termsHandler}>
                            <img src={Close} alt="close"/>
                        </button>
                        <div className="c-modal__content">
                            <div className="c-modal__body">
                                <div className="c-article">
                                    <div dangerouslySetInnerHTML={{__html: registerTerms}} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>

        )
    }

    _clickLogin() {
        this.props.history.push(RouteEnum.Login)
    }

}

export default connect(mapStateToProps)(Register);
