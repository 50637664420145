import React from 'react'
import '../App.css'
import Authentication from "./Authentication";
import LoggedIn from "./LoggedIn";
import {connect} from "react-redux";


const mapStateToProps = (state, ownProps) => ({
    ...state.account
});

class App extends React.Component {

    render() {

        const authenticated = this.props.signIn.data.token

        if (authenticated && authenticated !== 'undefined') {
            return <LoggedIn history={this.props.history}/>
        } else {
            return <Authentication history={this.props.history}/>
        }


    }

}

export default connect(mapStateToProps)(App);
