import React from 'react'
import FeedbackItem from "./_components/FeedbackItem";
import {connect} from "react-redux";
import FeedbackAction from "../../stores/api/feedback/FeedbackAction";
import Header from "../_components/Header";
import Loadscreen from "../_components/Loadscreen";
import RouteEnum from "../../constants/RouteEnum";


const mapStateToProps = (state, ownProps) => ({
    ...state.feedback.all
});

class FeedbackOverview extends React.Component {

    componentDidMount() {
        this.props.dispatch(FeedbackAction.requestAll())
    }

    totalChatCountSyndrome() {

        let syndromes = 0

        if (this.props.data.syndrome) {

            if (this.props.data.syndrome.new) {
                syndromes += Object.keys(this.props.data.syndrome.new).length
            }

            if (this.props.data.syndrome.closed) {
                syndromes += Object.keys(this.props.data.syndrome.closed).length
            }

            if (this.props.data.syndrome.open) {
                syndromes += Object.keys(this.props.data.syndrome.open).length
            }

        }


        return syndromes
    }

    totalChatCountMedicament() {

        let medicaments = 0

        if (this.props.data.medicament) {

            if (this.props.data.medicament.new) {
                medicaments += Object.keys(this.props.data.medicament.new).length
            }

            if (this.props.data.medicament.closed) {
                medicaments += Object.keys(this.props.data.medicament.closed).length
            }

            if (this.props.data.medicament.open) {
                medicaments += Object.keys(this.props.data.medicament.open).length
            }

        }

        return medicaments

    }

    render() {

        if (this.props.data.syndrome || this.props.data.medicament) {

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header">
                            <div className="c-subject-header--detail__text">
                                <h1>Mijn feedback</h1>
                            </div>
                        </header>

                        <div className="c-link-blocks">

                            {this.props.data.syndrome || this.props.data.medicament ? (

                                <div>

                                    {!!this.props.data.syndrome &&

                                    <div className="c-link-blocks__group">
                                        <div className="c-link-blocks__category">
                                            <span>Ziektebeelden</span>
                                            <div
                                                className="btn btn--number btn--number--white">{this.totalChatCountSyndrome()}</div>
                                        </div>

                                        {!!this.props.data.syndrome.new &&

                                        Object.values(this.props.data.syndrome.new).map((data) => (
                                            <FeedbackItem history={this.props.history} {...data} />))

                                        }

                                        {!!this.props.data.syndrome.open &&

                                        Object.values(this.props.data.syndrome.open).map((data) => (
                                            <FeedbackItem history={this.props.history} {...data} />))

                                        }

                                        {!!this.props.data.syndrome.closed &&

                                        Object.values(this.props.data.syndrome.closed).map((data) => (
                                            <FeedbackItem history={this.props.history} {...data} />))

                                        }

                                    </div>

                                    }

                                    {!!this.props.data.medicament &&

                                    <div className="c-link-blocks__group">
                                        <div className="c-link-blocks__category c-link-blocks__category--pink">
                                            <span>Medicamenten</span>
                                            <div
                                                className="btn btn--number btn--number--white">{this.totalChatCountMedicament()}</div>
                                        </div>

                                        {!!this.props.data.medicament.new &&

                                        Object.values(this.props.data.medicament.new).map((data) => (
                                            <FeedbackItem history={this.props.history} {...data} />))

                                        }

                                        {!!this.props.data.medicament.open &&

                                        Object.values(this.props.data.medicament.open).map((data) => (
                                            <FeedbackItem history={this.props.history} {...data} />))

                                        }

                                        {!!this.props.data.medicament.closed &&

                                        Object.values(this.props.data.medicament.closed).map((data) => (
                                            <FeedbackItem history={this.props.history} {...data} />))

                                        }

                                    </div>

                                    }

                                </div>

                            ) : (


                                <div className="c-notification c-notification--message">
                                    <i className="icon-info"/>
                                    <span>U heeft nog geen feedback gemeld. U kunt feedback geven op <a
                                        onClick={() => this._clickSyndromes()}>ziektebeelden</a> en <a
                                        onClick={() => this._clickMedicaments()}>medicamenten</a>.</span>
                                </div>

                            )}

                        </div>

                    </div>

                </main>

            )

        } else {

            if (this.props.error.code === 200) {

                return (

                    <main>
                        <Header history={this.props.history}/>
                        <div className="o-container o-container--primary">

                            <header className="c-subject-header">
                                <div className="c-subject-header--detail__text">
                                    <h1>Mijn feedback</h1>
                                </div>
                            </header>

                            <div className="c-notification c-notification--message">
                                <i className="icon-info"/>
                                <span>U heeft nog geen feedback conversaties, ga naar een medicament/ziektebeeld om feedback te geven.</span>
                            </div>

                        </div>
                    </main>

                )

            } else {

                return <Loadscreen/>

            }

        }

    }

    _clickSyndromes() {
        this.props.history.push(RouteEnum.Syndromes)
    }

    _clickMedicaments() {
        this.props.history.push(RouteEnum.Medicaments)
    }

}

export default connect(mapStateToProps)(FeedbackOverview);
