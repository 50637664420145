import React from 'react'
import Loader from 'react-loader-spinner'

class Loadscreen extends React.Component {



    render() {

        return (

            <div className='c-loadscreen'>

                <Loader
                    type="Puff"
                    color="rgb(81, 176, 170)"
                    height={100}
                    width={100}
                    timeout={3000} //3 secs

                />

            </div>

        )

    }


}

export default Loadscreen;
