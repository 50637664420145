import ApiEnum from "../../../constants/ApiEnum";

export default class FeedbackEffect {

    static async requestSingle(id) {

        return await fetch(ApiEnum.Api + '/feedback/single?post_id=' + id, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {

            if (response.status.code === 200) {
                return {
                    data: response.data,
                    error: response.status
                }
            } else {
                return {
                    message: response.status.message
                }
            }
        }).catch(function (error) {

            return {
                error: true,
                message: error.message
            }
        })

    }

    static async requestAll() {

        return await fetch(ApiEnum.Api + '/feedback/all?user_id=' + localStorage.getItem('id'), {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.status.code === 200) {
                return {
                    data: response.data,
                    error: response.status
                }
            } else {
                return {
                    message: response.status.message
                }
            }
        }).catch(function (error) {

            return {
                error: true,
                message: error.message
            }
        })


    }


    static async requestCreate(id) {

        return await fetch(ApiEnum.Api + '/feedback/create?user_id=' + localStorage.getItem('id') + '&post_id=' + id, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.status.code === 200) {
                return {
                    data: response.data,
                    error: response.status
                }
            } else {
                return {
                    message: response.status.message
                }
            }
        }).catch(function (error) {

            return {
                error: true,
                message: error.message
            }
        })


    }

    static async requestUpdate(id, values) {

        return await fetch(ApiEnum.Api + '/feedback/update?user_id=' + localStorage.getItem('id') + '&post_id=' + id, {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
                return response.json()
            }
        )
    }

    static async requestClose(id) {

        return await fetch(ApiEnum.Api + '/feedback/close?post_id=' + id, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {

            if (response.status.code === 200) {
                return {
                    data: response.data,
                    error: response.status
                }
            } else {
                return {
                    message: response.status.message
                }
            }
        }).catch(function (error) {

            return {
                error: true,
                message: error.message
            }
        })

    }

}
