import React from 'react'
import {connect} from "react-redux";
import MedicamentAction from "../../stores/api/medicament/MedicamentAction";
import Flex from "../_components/Flex";
import MedicamentItem from "./_components/MedicamentItem";
import SyndromeItem from "../syndromes/_components/SyndromeItem";
import RouteEnum from "../../constants/RouteEnum";
import FeedbackAction from "../../stores/api/feedback/FeedbackAction";
import Header from "../_components/Header";
import Loadscreen from "../_components/Loadscreen";

const mapStateToProps = (state, ownProps) => ({
    ...state.medicament.single,
    ...state.feedback
});

class MedicamentDetail extends React.Component {

    init() {
        const id = this.props.match.params.id;
        this.props.dispatch(MedicamentAction.requestSingle(id))
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init()
        }
    }


    render() {

        if (this.props.data) {

            const data = this.props.data
            const acfData = this.props.data.acfData
            const syndromeRelated = this.props.data.relatedSyndrome
            const medicamentRelated = this.props.data.relatedMedicamant

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header c-subject-header--detail">
                            <div className="c-subject-header--detail__text">
                                <a onClick={() => this._clickMedicament()}><small> &lt; </small><span>medicamenten</span></a>
                                <h1>{data.title}</h1>
                            </div>
                            <div className="btn--timed">
                                <i className="icon-clock"/>
                                <span>laatst bijgewerkt op {data.lastModified}</span>
                            </div>
                        </header>

                        <div className="c-detail">

                            <div className="c-detail__primary">
                                {!!acfData.content &&
                                <article className="c-article">
                                    {acfData.content.map((item) => (<Flex {...item} />))}
                                </article>
                                }
                            </div>

                            <div className="c-detail__secondary">
                                <div className="c-link-blocks">

                                    {!!syndromeRelated &&

                                    <div className="c-link-blocks__group">
                                        <div className="c-link-blocks__category">
                                            <span>Gekoppelde ziektebeelden</span>
                                            <div
                                                className="btn btn--number btn--number--white">{Object.keys(syndromeRelated).length}</div>
                                        </div>


                                        {Object.values(syndromeRelated).map((data) => (
                                            <SyndromeItem history={this.props.history} {...data} />))}


                                    </div>

                                    }

                                    {!!medicamentRelated &&

                                    <div className="c-link-blocks__group">
                                        <div className="c-link-blocks__category c-link-blocks__category--pink">
                                            <span>Gerelateerde medicamenten</span>
                                            <div
                                                className="btn btn--number btn--number--white">{Object.keys(medicamentRelated).length}</div>
                                        </div>


                                        {Object.values(medicamentRelated).map((data) => (<MedicamentItem
                                            history={this.props.history} {...data} />))}


                                    </div>

                                    }

                                </div>

                                <div className="c-detail__secondary__cta">
                                    <div className="c-detail__secondary__cta__buttons">
                                        {!!acfData.versiegeschiedenis &&
                                        <a onClick={() => this._clickVersionHistory()}
                                           className="btn btn--large btn--outline-green"><span>Bekijk versiegeschiedenis ({Object.keys(acfData.versiegeschiedenis).length})</span></a>
                                        }
                                        <a onClick={() => this._createFeedback()}
                                           className="btn btn--large btn--green"><span>Feedback geven</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </main>

            )

        } else {

            return <Loadscreen/>

        }

    }

    _clickMedicament() {
        this.props.history.push(RouteEnum.Medicaments)
    }

    _createFeedback() {
        const id = this.props.match.params.id;
        this.props.dispatch((FeedbackAction.requestCreate(id))).then(() => {
            this.props.history.push(RouteEnum.Feedback + '/' + this.props.create.data)
        })
    }

    _clickVersionHistory() {
        const id = this.props.match.params.id;
        this.props.history.push(RouteEnum.MedicamentsVersion + '/' + id)
    }

}

export default connect(mapStateToProps)(MedicamentDetail);
