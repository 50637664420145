import ActionUtility from "../../../utilities/etc/ActionUtility";
import FeedbackEffect from "./FeedbackEffect";


export default class FeedbackAction {

    static REQUEST_SINGLE = 'FeedbackAction.REQUEST_SINGLE';
    static REQUEST_ALL = 'FeedbackAction.REQUEST_ALL';
    static REQUEST_CREATE = 'FeedbackAction.REQUEST_CREATE';
    static REQUEST_UPDATE = 'FeedbackAction.REQUEST_UPDATE';
    static REQUEST_CLOSE = 'FeedbackAction.REQUEST_CLOSE';

    static requestSingle(id) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, FeedbackAction.REQUEST_SINGLE, FeedbackEffect.requestSingle, id);
        }

    }

    static requestAll() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, FeedbackAction.REQUEST_ALL, FeedbackEffect.requestAll)
        }

    }

    static requestCreate(id) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, FeedbackAction.REQUEST_CREATE, FeedbackEffect.requestCreate, id);
        }

    }

    static requestUpdate(id, values) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, FeedbackAction.REQUEST_UPDATE, FeedbackEffect.requestUpdate, id, values);
        }

    }

    static requestClose(id) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, FeedbackAction.REQUEST_CLOSE, FeedbackEffect.requestClose, id);
        }

    }
}
