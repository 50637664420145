import React from 'react'

class UlList extends React.Component{

    render() {

        const rows = this.props.rij

        if(rows) {

            return (

                <ul>

                    {rows.map((row) => (

                        <li>
                            <div dangerouslySetInnerHTML={{__html: row.tekst}}/>
                        </li>

                    ))}

                </ul>

            )

        }else{
            return null
        }

    }

}

export default UlList
