import React from 'react'
import RouteEnum from "../../../constants/RouteEnum";

class SubjectSubItem extends React.Component {

    render() {

        return (

            <a onClick={() => this._click()} className="c-link-blocks__single__body__item">
                <small>{this.props.name}</small>
                <div className="btn--pill"><span>{this.props.count} {this._syndromeText()}</span></div>
            </a>

        )

    }

    _click(){
        const id = this.props.id;
        this.props.history.push(RouteEnum.Subjects + '/' + id)
    }

    _syndromeText(){
        if (this.props.count === 1){
            return 'ziektebeeld'
        }else {
            return 'ziektebeelden'
        }
    }

}

export default SubjectSubItem
