import React from 'react'

class Divider extends React.Component{

    render() {

        return(

            <hr/>

        )
    }
}

export default Divider
