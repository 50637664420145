import React from 'react'
import RouteEnum from "../../../../constants/RouteEnum";

class Button extends React.Component {

    render() {

        if (this.props.type === 'extern') {

            return (

                <a href={this.props.link_extern} target='_blank' className={"btn btn--large btn--icon btn--" + this.props.kleur + ""}> {!!this.props.toon_intern_icoon &&
                <i className="icon-internal"/>} <span>{this.props.tekst}</span></a>

            )

        }else{

            return (

                <a onClick={() => this._click()} className={"btn btn--large btn--icon btn--" + this.props.kleur + ""}> {!!this.props.toon_intern_icoon &&
                <i className="icon-internal"/>} <span>{this.props.tekst}</span></a>

            )

        }

    }

    _click(){

        if (this.props.type === 'post'){

            if (this.props.link_post.post_type === 'ziektebeelden'){
                this.props.history.push(RouteEnum.Syndromes + '/' + this.props.link_post.ID)
            }else if (this.props.link_post.post_type === 'medicamenten'){
                this.props.history.push(RouteEnum.Medicaments + '/' + this.props.link_post.ID)
            }

        }else if (this.props.type === 'taxonomy'){
            this.props.history.push(RouteEnum.Subjects + '/' + this.props.link_taxonomy)
        }

    }

}

export default Button
