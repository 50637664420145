import React from 'react'
import SyndromeVersionItem from "./_components/SyndromeVersionItem";
import {connect} from "react-redux";
import SyndromeAction from "../../stores/api/syndrome/SyndromeAction";
import Header from "../_components/Header";
import Loadscreen from "../_components/Loadscreen";
import FeedbackAction from "../../stores/api/feedback/FeedbackAction";
import RouteEnum from "../../constants/RouteEnum";

const mapStateToProps = (state, ownProps) => ({
    ...state.syndrome.single,
    ...state.feedback
});

class SyndromeVersion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadedId: false
        }
    }

    init() {
        const id = this.props.match.params.id;
        if (id !== this.state.loadedId) {
            this.props.dispatch(SyndromeAction.requestSingle(id)).then(() => {
                this.setState({
                    loadedId: id
                })
            })
        }
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate() {
        this.init()
    }


    render() {

        if (this.props.data) {

            const acfData = this.props.data.acfData

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header c-subject-header--detail">
                            <div className="c-subject-header--detail__text">
                                <a onClick={() => this._clickSyndrome()}><small>&lt;</small><span>{this.props.data.title}</span></a>
                                <h1>Versiegeschiedenis - {this.props.data.title}</h1>
                            </div>
                        </header>

                        {acfData.versiegeschiedenis ? (

                            <div className="c-version-control">
                                {acfData.versiegeschiedenis.map((item) => (<SyndromeVersionItem {...item} />))}
                            </div>

                        ) : (

                            <div className="c-notification c-notification--message">
                                <i className="icon-info"/>
                                <span>Dit ziektebeeld heeft nog geen wijzigingen.</span>
                            </div>

                        )}

                        <a onClick={() => this._createFeedback()}
                           className="btn--green btn--large btn--float-center-bottom">
                            <span>Feedback geven</span>
                        </a>

                    </div>
                </main>

            )

        } else {

            return <Loadscreen/>

        }

    }

    _createFeedback() {
        const id = this.props.match.params.id;
        this.props.dispatch((FeedbackAction.requestCreate(id))).then(() => {
            this.props.history.push(RouteEnum.Feedback + '/' + this.props.create.data)
        })
    }

    _clickSyndrome() {
        const id = this.props.match.params.id;
        this.props.history.push(RouteEnum.Syndromes + '/' + id)
    }

}

export default connect(mapStateToProps)(SyndromeVersion);
