import React from 'react';
import Plus from "../../../../images/plus.svg";
import Close from "../../../../images/close.svg";
import Image from "./Image";

class ImagePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggled: false
        }

        this._toggle = this._toggle.bind(this)
    }

    render() {

        let modalClass = 'c-modal'

        if (this.state.toggled) {
            modalClass += ' c-modal--active'
        }

        if (this.props.afbeelding) {
            return (
                <div>
                    <button className="c-image-modal-trigger" onClick={this._toggle}>
                        <img src={this.props.afbeelding.sizes.large} alt={this.props.alt}/>
                        <div className="c-image-modal-trigger__plus">
                            <img src={Plus} alt="plus"/>
                        </div>
                    </button>
                    <br/>
                    <small>{this.props.onderschrift}</small><br/>
                    <div className={modalClass}>
                        <div className="c-modal__bg" onClick={this._toggle}/>
                        <button className="c-modal__close" onClick={this._toggle}>
                            <img src={Close} alt="close"/>
                        </button>
                        {!!this.props.afbeelding &&
                        <div className="c-modal__content" style={{backgroundColor: "transparent"}}>
                            <Image afbeelding={this.props.afbeelding}/>
                        </div>
                        }
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    _toggle = () => {
        this.setState({
            toggled: !this.state.toggled
        })
    }

}

export default ImagePopup
