import React from 'react'
import {connect} from "react-redux";
import SyndromeAction from "../../stores/api/syndrome/SyndromeAction";
import Flex from "../_components/Flex";
import RouteEnum from "../../constants/RouteEnum";
import SyndromeItem from "./_components/SyndromeItem";
import SyndromeBron from "./_components/SyndromeBron";
import FeedbackAction from "../../stores/api/feedback/FeedbackAction";
import Header from "../_components/Header";
import Loadscreen from "../_components/Loadscreen";


const mapStateToProps = (state, ownProps) => ({
    ...state.syndrome.single,
    ...state.feedback
});

class SyndromeDetail extends React.Component {

    init() {
        const id = this.props.match.params.id;
        this.props.dispatch(SyndromeAction.requestSingle(id))
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init()
        }
    }

    render() {

        if (this.props.data) {

            const data = this.props.data
            const acfData = this.props.data.acfData
            const related = this.props.data.related

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header c-subject-header--detail">
                            <div className="c-subject-header--detail__text">
                                <a onClick={() => this._clickSyndrome()}><small>&lt;</small><span>ziektebeelden</span></a>
                                <h1>{data.title}</h1>
                                <small>{!!data.parentSubject.name &&<i>{data.parentSubject.name} ></i>} <a onClick={() => this._clickSubject(data.subject.term_id)}>{data.subject.name}</a></small>
                            </div>
                            <div className="btn--timed">
                                <i className="icon-clock"/>
                                <span>laatst bijgewerkt op {data.lastModified}</span>
                            </div>
                        </header>

                        <div className="c-detail">

                            <div className="c-detail__primary">
                                {!!acfData.content &&
                                <article className="c-article">
                                    {acfData.content.map((item) => (<Flex history={this.props.history} {...item} />))}
                                </article>
                                }
                            </div>

                            <div className="c-detail__secondary">
                                <div className="c-link-blocks">

                                    {!!related &&

                                    <div className="c-link-blocks__group">
                                        <div className="c-link-blocks__category">
                                            <span>Gerelateerde ziektebeelden</span>
                                            <div className="btn btn--number btn--number--white">{Object.keys(related).length}</div>
                                        </div>

                                        {Object.values(related).map((data) => (
                                            <SyndromeItem history={this.props.history} {...data} />
                                        ))}

                                    </div>

                                    }

                                    <div className="c-link-blocks__group">
                                        <div className="c-link-blocks__category">
                                            <span>Hoofd- en subonderwerpen</span>
                                        </div>
                                        <a onClick={() => this._clickSubject(data.subject.term_id)}
                                           className="c-link-blocks__single">
                                            <div className="c-link-blocks__single__header">
                                                <div className="c-link-blocks__single__text">
                                                    <span>{data.subject.name}</span>
                                                    <small>{!!data.parentSubject.name &&
                                                    <i>{data.parentSubject.name} ></i>} {data.subject.name}</small>
                                                </div>
                                                <div className="btn--pill"><span>{data.subject.count} ziektebeelden</span></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="c-detail__secondary__cta">


                                    {!!acfData.bronnen &&
                                    <div>
                                        <h6>Bronnen</h6>
                                        <ul className="u-reset-ul">

                                            {acfData.bronnen.map((item) => (
                                                <SyndromeBron {...item} />
                                            ))}

                                        </ul>
                                    </div>
                                    }

                                    <div className="c-detail__secondary__cta__buttons">
                                        {!!acfData.versiegeschiedenis &&
                                        <a onClick={() => this._clickVersionHistory()}
                                           className="btn btn--large btn--outline-green"><span>Bekijk versiegeschiedenis ({Object.keys(acfData.versiegeschiedenis).length})</span></a>
                                        }
                                        <a onClick={() => this._createFeedback()} className="btn btn--large btn--green"><span>Feedback geven</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            )

        } else {

            return <Loadscreen/>

        }


    }

    _clickSyndrome() {
        this.props.history.push(RouteEnum.Syndromes)
    }

    _clickSubject(id) {
        this.props.history.push(RouteEnum.Subjects + '/' + id)
    }

    _createFeedback() {
        const id = this.props.match.params.id;
        this.props.dispatch((FeedbackAction.requestCreate(id))).then(() => {
            this.props.history.push(RouteEnum.Feedback + '/' + this.props.create.data)
        })
    }

    _clickVersionHistory() {
        const id = this.props.match.params.id;
        this.props.history.push(RouteEnum.SyndromesVersions + '/' + id)
    }

}

export default connect(mapStateToProps)(SyndromeDetail);
