import React from 'react'
import SubjectMainItem from "./_components/SubjectMainItem";
import {connect} from "react-redux";
import SubjectAction from "../../stores/api/subject/SubjectAction";
import Header from "../_components/Header";
import sortAlphabetically from "../../utilities/etc/sortAlphabetically";
import Loadscreen from "../_components/Loadscreen";

const mapStateToProps = (state, ownProps) => ({
    ...state.subject.all
});

class SubjectOverview extends React.Component {

    componentDidMount() {
        this.props.dispatch(SubjectAction.requestAll())
    }


    render() {

        if (this.props.data) {

            const items = Object.values(this.props.data)
            sortAlphabetically(items)

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header c-subject-header--search">
                            <h1>Hoofd- en subonderwerpen</h1>
                        </header>

                        <div className="c-link-blocks">
                            <div className="c-link-blocks__group">

                                {Object.values(items).map((data) => (
                                    <SubjectMainItem history={this.props.history} {...data} />))}

                            </div>
                        </div>

                    </div>
                </main>

            )

        } else {

            return <Loadscreen/>

        }

    }

}

export default connect(mapStateToProps)(SubjectOverview);
