import ApiEnum from "../../../constants/ApiEnum";

export default class MedicamentEffect {

    static async requestSingle(id) {

            return await fetch(ApiEnum.Api + '/medicament/single?post_id=' + id, {
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            }).then((response) => {
                    return response.json()
                }
            ).then((response) => {
                if (response.status.code === 200) {
                    return {
                        data: response.data,
                        error: response.status
                    }
                } else {
                    return {
                        message: response.status.message
                    }
                }
            }).catch(function (error) {

                return {
                    error: true,
                    message: error.message
                }
            })

    }

    static async requestAll() {

        return await fetch(ApiEnum.Api + '/medicament/all', {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.status.code === 200) {
                return {
                    data: response.data,
                    error: response.status
                }
            } else {
                return {
                    message: response.status.message
                }
            }
        }).catch(function (error) {

            return{
                error: true,
                message: error.message
            }
        })


    }


    static async requestSearch(term) {

        return await fetch(ApiEnum.Api + '/medicament/search?search_term=' + term, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.status.code === 200) {
                return {
                    data: response.data,
                    error: response.status
                }
            } else {
                return {
                    message: response.status.message
                }
            }
        }).catch(function (error) {

            return{
                error: true,
                message: error.message
            }
        })


    }

}
