import ActionUtility from "../../../utilities/etc/ActionUtility";
import SubjectEffect from "./SubjectEffect";


export default class SubjectAction {

    static REQUEST_SINGLE = 'SubjectAction.REQUEST_SINGLE';
    static REQUEST_ALL = 'SubjectAction.REQUEST_ALL';
    static REQUEST_SEARCH = 'SubjectAction.REQUEST_SEARCH';

    static requestSingle(id) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, SubjectAction.REQUEST_SINGLE, SubjectEffect.requestSingle, id);
        }

    }

    static requestAll() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, SubjectAction.REQUEST_ALL, SubjectEffect.requestAll)
        }

    }

    static requestSearch(term) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, SubjectAction.REQUEST_SEARCH, SubjectEffect.requestSearch, term);
        }

    }
}
