import React from 'react'
import AccountAction from "../../stores/api/account/AccountAction";
import {connect} from "react-redux";
import logo from "../../images/ZBVO-Formularium-logo.svg"
import RouteEnum from "../../constants/RouteEnum";

const mapStateToProps = (state, ownProps) => ({
    ...state.account
});

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            token: '',
            username: '',
            password: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault()
        const LoginData = {
            username: this.state.username,
            password: this.state.password
        }

        this.props.dispatch(AccountAction.requestSignIn(LoginData))

    }


    render() {

        let LoggedInCheck = false
        let SignOutCheck = false

        if (this.props.signIn.data === 'failed'){
            LoggedInCheck = true
        }

        if (this.props.signOut === true){
            SignOutCheck = true
        }

        const {username, password} = this.state

        let usernameLabelClass = 'c-auth-label'
        let passwordLabelClass = 'c-auth-label'

        if(this.state.username){
            usernameLabelClass += ' c-auth-label--active'
        }

        if(this.state.password){
            passwordLabelClass += ' c-auth-label--active'
        }

        return (

            <main>
                <div className="c-auth">
                    <img src={logo} alt="logo" className="c-auth__logo"/>


                    <div className="c-auth__text">
                        <h1>Inloggen</h1>
                    </div>
                    <form onSubmit={this.handleSubmit} className="c-auth__form">
                        <div className="c-auth__form__group">
                            <input type="text" id="email" name='username' value={username}
                                   onChange={this.handleChange}/>
                            <label className={usernameLabelClass} htmlFor="email">E-mailadres</label>
                        </div>
                        <div className="c-auth__form__group">
                            <input type="password" id="password" name='password' value={password}
                                   onChange={this.handleChange}/>
                            <label className={passwordLabelClass} htmlFor="password">Uw wachtwoord</label>
                        </div>

                        {!!LoggedInCheck &&

                        <div className="c-notification c-notification--error">
                            <i className="icon-info"/>
                            <span>Combinate van e-mailadres en wachtwoord komt niet overeen. Probeer het a.u.b. opnieuw.</span>
                        </div>

                        }

                        {!!SignOutCheck &&

                        <div className="c-notification c-notification--message">
                            <i className="icon-info"/>
                            <span>U bent uitgelogd.</span>
                        </div>

                        }

                        <button className="c-auth__form__button btn--large btn--green">
                            <span>Inloggen</span>
                        </button>
                        <a onClick={() => this._clickForgotPassword()} className="c-auth__form__link">Ik ben mijn wachtwoord vergeten</a>
                    </form>
                    <a onClick={() => this._clickRegister()} className="btn--underline btn--float-center-bottom">
                        <span>Account aanvragen</span>
                    </a>

                </div>
            </main>

        )
    }

    _clickRegister() {
        this.props.history.push(RouteEnum.Register)
    }

    _clickForgotPassword() {
        this.props.history.push(RouteEnum.ForgotPassword)
    }

}

export default connect(mapStateToProps)(Login);
