import React from 'react'
import Menu from "./Menu";
import RouteEnum from "../../constants/RouteEnum";
import logo from "../../images/ZBVO-Formularium-logo.svg"


class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggled: false
        }

        this._toggle = this._toggle.bind(this)
    }

    render() {

        let menuClass = ''

        if(this.state.toggled){
            menuClass += 'menuActive'
        }

        return (
            <div className={menuClass}>
                <header className="c-header">
                    <a className="c-header__logo" onClick={() => this._clickDashboard()}>
                        <img src={logo} alt="logo"/>
                    </a>
                    <nav className="c-header__nav-primary">
                        <ul className="u-reset-ul">
                            <li><a onClick={() => this._clickDashboard()}>Dashboard</a></li>
                            <li><a onClick={() => this._clickSubjects()}>Hoofd- en subonderwerpen</a></li>
                            <li><a onClick={() => this._clickSyndromes()}>Ziektebeelden</a></li>
                            <li><a onClick={() => this._clickMedicaments()}>Medicamenten</a></li>
                        </ul>
                    </nav>
                    <div className="c-header__nav-secondary">
                        <button onClick={() => this._clickSearch()} className="c-header__search-trigger">
                            <i className="icon-search"/>
                        </button>
                        <button className="c-header__menu-trigger" onClick={() => this._toggle()}>
                            <div>
                                <small/>
                                <small/>
                                <small/>
                            </div>
                            <span>menu</span>
                        </button>
                    </div>
                </header>

                <Menu history={this.props.history} toggle={this._toggle}/>
            </div>
        )

    }

    _toggle() {
        this.setState({
            toggled: !this.state.toggled
        })
    }

    _clickDashboard(){
        this.props.history.push(RouteEnum.Dashboard)
    }

    _clickSubjects(){
        this.props.history.push(RouteEnum.Subjects)
    }

    _clickSyndromes(){
        this.props.history.push(RouteEnum.Syndromes)
    }

    _clickMedicaments(){
        this.props.history.push(RouteEnum.Medicaments)
    }

    _clickSearch(){
        this.props.history.push(RouteEnum.Search)
    }

}

export default Header;
