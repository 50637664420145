import React from 'react'


class ConversationItem extends React.Component {

    render() {

        let isAdmin = true
        let author = 'admin'

        if (!this.props.admin){
            isAdmin = false
            author = this.props.user
        }

        let conversationClass = 'c-conversation__single'

        if(isAdmin){
            conversationClass += ' c-conversation__single--admin'
        }

        return (

            <div className={conversationClass}>
                <header>
                    <div>
                        <i className="icon-clock"/>
                        <span>{this.props.datum} / {this.props.tijd}</span>
                    </div>
                    <span>{author}</span>
                </header>
                <div className="c-conversation__single__body">
                    <p>{this.props.opmerking}</p>
                </div>
            </div>

        )

    }



}

export default ConversationItem
