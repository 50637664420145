import React from 'react'

class MedicamentVersionItem extends React.Component{

    render() {

        return(

            <div className="c-version-control__single">
                <header className="c-version-control__single__header">
                    <i className="icon-clock"/>
                    <h2>{this.props.datum}</h2>
                </header>
                <div className="c-article">
                    <div dangerouslySetInnerHTML={{__html: this.props.tekst}} />
                </div>
            </div>

        )

    }

}

export default MedicamentVersionItem
