import React from 'react'

class WhiteSpace extends React.Component{

    render() {

        return(

            <div>
                <br/><br/><br/><br/>
            </div>

        )

    }

}

export default WhiteSpace
