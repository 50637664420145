import React from 'react';
import {Route, Switch, HashRouter} from 'react-router-dom';
import RouteEnum from "../constants/RouteEnum";
import Login from "./authenticate/Login";
import Register from "./authenticate/Register";
import RegisterPassword from './authenticate/RegisterPassword';
import ForgotPassword from "./authenticate/ForgotPassword";
import ResetPassword from "./authenticate/ResetPassword";

class Authentication extends React.Component {


    render() {

        const props = this.props

        return (

            <HashRouter history={props.history} basename={''}>
                <Switch>

                    <Route
                        exact
                        path={RouteEnum.Login}
                        history={this.props.history}
                        component={Login}/>
                    <Route
                        exact
                        path={RouteEnum.Register}
                        history={this.props.history}
                        component={Register}/>
                    <Route
                        exact
                        path={RouteEnum.RegisterPassword + '/:id/:hash'}
                        history={this.props.history}
                        component={RegisterPassword}/>
                    <Route
                        exact
                        path={RouteEnum.ForgotPassword}
                        history={this.props.history}
                        component={ForgotPassword}/>
                    <Route
                        exact
                        path={RouteEnum.ResetPassword + '/:id/:hash'}
                        history={this.props.history}
                        component={ResetPassword}/>

                    <Route component={Login}/>

                </Switch>

            </HashRouter>
        )

    }
}

export default Authentication
