import React from 'react'
import {connect} from "react-redux";
import FeedbackAction from "../../stores/api/feedback/FeedbackAction";
import ConversationItem from "./_components/ConversationItem";
import RouteEnum from "../../constants/RouteEnum";
import Header from "../_components/Header";
import Loadscreen from "../_components/Loadscreen";

const mapStateToProps = (state, ownProps) => ({
    ...state.feedback
});

class FeedbackDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    init() {
        const id = this.props.match.params.id;
        this.props.dispatch(FeedbackAction.requestSingle(id))
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init()
        }
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        const id = this.props.match.params.id;
        this.props.dispatch(FeedbackAction.requestUpdate(id, {commentary: this.state.value})).then(() => {
            window.location.reload(false);
        })
        event.preventDefault()
    }

    render() {

        let acfData = false;

        if (this.props.single.data) {
            acfData = this.props.single.data.acfData
        }

        if (this.props.single.data) {

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header c-subject-header--detail">
                            <div className="c-subject-header--detail__text">
                                <a onClick={() => this._clickFeedback()}>
                                    <small> &lt; </small><span>mijn feedback</span></a>
                                <h1>{this.props.single.data.title}</h1>
                                {!!this.props.single.data.subject &&
                                <small>{!!this.props.single.data.parentSubject.name &&<i>{this.props.single.data.parentSubject.name} ></i> } {this.props.single.data.subject.name}</small>
                                }
                            </div>
                        </header>

                        <div className="c-conversation">

                            {acfData.opmerkingen ? (

                                acfData.opmerkingen.map((item) => (
                                    <ConversationItem user={this.props.single.data.user} {...item} />))

                            ) : (

                                acfData.discussie_gesloten ? (
                                    <div className="c-notification c-notification--message">
                                        <i className="icon-info"/>
                                        <span>Dit medicament/ziektebeeld heeft geen berichten.</span>
                                    </div>
                                ) : (
                                    <div className="c-notification c-notification--message">
                                        <i className="icon-info"/>
                                        <span>Dit medicament/ziektebeeld heeft nog geen feedback, schrijf een bericht om een gesprek te starten.</span>
                                    </div>
                                )

                            )}

                            {!!acfData.discussie_gesloten &&

                            <div className="c-notification c-notification--message">
                                <i className="icon-lock"/>
                                <span>Dit gesprek is door u of de admin gesloten. Meer feedback geven over dit medicament/ziektebeeld? <a
                                    onClick={() => this._createFeedback()}>Klik hier</a> om een nieuw gesprek te starten.</span>
                            </div>


                            }

                            {!acfData.discussie_gesloten &&
                            <form onSubmit={this.handleSubmit} className="c-conversation__action">
                                <div className="c-conversation__input">
                                <textarea name="commentary" id="" cols="30" rows="5"
                                          placeholder="Plaats een opmerking..." value={this.state.value}
                                          onChange={this.handleChange}/>
                                    <div>
                                        <a onClick={() => this._closeDetail()}
                                           className="btn--large btn--outline-green c-conversation__close">
                                            <i className="icon-lock"/>
                                            <span>Discussie sluiten</span>
                                        </a>
                                        <button type="submit" className="btn--large btn--green">
                                            <span>Feedback versturen</span></button>
                                    </div>
                                </div>
                            </form>
                            }

                        </div>

                    </div>

                </main>

            )

        } else {

            return <Loadscreen/>

        }

    }


    _closeDetail() {
        const id = this.props.match.params.id;
        this.props.dispatch(FeedbackAction.requestClose(id)).then(() => {
            this.props.history.push(RouteEnum.Feedback)
        })
    }

    _clickFeedback() {
        this.props.history.push(RouteEnum.Feedback)
    }

    _createFeedback() {
        const id = this.props.single.data.subjectId;
        this.props.dispatch((FeedbackAction.requestCreate(id))).then(() => {
            this.props.history.push(RouteEnum.Feedback + '/' + this.props.create.data)
        })
    }

}

export default connect(mapStateToProps)(FeedbackDetail);
