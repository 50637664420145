import React from 'react'

class OlList extends React.Component {

    render() {

        const rows = this.props.rij

        if (rows) {

            return (

                <ol>

                    {rows.map((row) => (

                        <li>
                            <div dangerouslySetInnerHTML={{__html: row.tekst}}/>
                        </li>

                    ))}

                </ol>

            )

        } else{
            return null
        }

    }

}

export default OlList
