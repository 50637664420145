import React from 'react'
import Flex from "../index";

class Accordion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggled: false
        }
    }

    render() {

        let accordionClass = 'c-accordion__single'

        if(this.state.toggled){
            accordionClass += ' c-accordion__single--active'
        }

        return (

            <div className={accordionClass} onClick={() => this._toggle()}>
                <header className="c-accordion__single__header">
                    <span>{this.props.titel}</span>
                    <i className="icon-arrow-right"/>
                </header>
                {!!this.props.accordion &&
                <div className="c-accordion__single__content">
                    {this.props.accordion.map((item) => (<Flex {...item} />))}
                </div>
                }
            </div>

        )
    }

    _toggle() {

        this.setState({
            toggled: !this.state.toggled
        })

    }

}

export default Accordion
