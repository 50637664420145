import InfoAction from "./InfoAction";

export default class InfoReducer {

    static initialState = {

        single: {
            data: false,
            error: false
        },

    }

    static reducer(state = InfoReducer.initialState, action) {
        switch (action.type) {
            case InfoAction.REQUEST_SINGLE:
                return {
                    ...state,
                    single: {
                        error: false,
                        data: false
                    },
                };
            case InfoAction.REQUEST_SINGLE + '_FINISHED':
                return {
                    ...state,
                    single: {
                        error: action.payload.error,
                        data: action.payload.data
                    },
                };
            default:
                return state;

        }
    }

}
