import React from 'react'
import MedicamentItem from "./_components/MedicamentItem";
import {connect} from "react-redux";
import MedicamentAction from "../../stores/api/medicament/MedicamentAction";
import Header from "../_components/Header";
import SortItems from "../../utilities/etc/SortItems";
import Loadscreen from "../_components/Loadscreen";

const mapStateToProps = (state, ownProps) => ({
    ...state.medicament
});

class MedicamentOverview extends React.Component {

    constructor(props) {
        super(props);
        this.searching = this.searching.bind(this);
    }

    state = {
        value: '',
        typingTimeout: 0,
        searching: false,
        searchResult: false,
        timer: null
    }

    searching = (event) => {
        const self = this;

        self.setState({
            searchResult: true
        });

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            value: event.target.value,
            typingTimeout: setTimeout(function () {
                self.searchQuery(self.state.value);
            }, 500),
        });

        if (self.state.timer) {
            clearTimeout(self.state.timer)
            self.state.timer = null
        }

        self.setState({
            timer: setTimeout(function () {
                if (!Array.isArray(self.props.search.data)) {
                    self.setState({
                        searchResult: true
                    });
                } else {
                    self.setState({
                        searchResult: false
                    });
                }
            }, 1500)
        })


        if (event.target.value) {
            self.setState({
                searching: true
            })
        } else {
            self.setState({
                searching: false
            })
        }

    }

    searchQuery = () => {
        this.props.dispatch(MedicamentAction.requestSearch(this.state.value))
    }

    init() {
        this.props.dispatch(MedicamentAction.requestAll())
    }

    componentDidMount() {
        this.init()
    }

    alphabeticItems() {

        let items = ''

        if (this.state.searching) {
            items = SortItems(this.props.search.data)
        } else {
            items = SortItems(this.props.all.data)
        }

        const check = Array.isArray(this.props.search.data)

        return (

            this.state.searching ? (

                !check ? (

                    Object.keys(items).map((itemId) => (

                        <div className="c-link-blocks__group">
                            <div className="c-link-blocks__category c-link-blocks__category--pink">
                                <span>{itemId}</span>
                            </div>

                            {items[itemId].map((data) => (<MedicamentItem history={this.props.history} {...data} />))}

                        </div>

                    ))

                ) : (

                    <div>

                        {!this.state.searchResult &&

                        <div className="c-notification c-notification--message">
                            <i className="icon-cross"/>
                            <span>Helaas geen resultaten gevonden, probeer een andere zoekterm.</span>
                        </div>

                        }

                    </div>

                )

            ) : (

                Object.keys(items).map((itemId) => (

                    <div className="c-link-blocks__group">
                        <div className="c-link-blocks__category c-link-blocks__category--pink">
                            <span>{itemId}</span>
                        </div>

                        {items[itemId].map((data) => (<MedicamentItem history={this.props.history} {...data} />))}

                    </div>

                ))

            )

        )
    }

    render() {

        if (this.props.all.data) {

            return (

                <main>
                    <Header history={this.props.history}/>
                    <div className="o-container o-container--primary">

                        <header className="c-subject-header c-subject-header--search">
                            <h1><i className="icon-medicine"/> Medicamenten</h1>
                            <div className="c-search-box">
                                <div className="c-search-box__input">
                                    <input type="text" placeholder="Zoek naar medicamenten" onChange={this.searching}/>
                                    <div>
                                        <i className="icon-search"/>
                                    </div>
                                </div>
                            </div>
                        </header>

                        {!!this.props.all.data &&

                        <div className="c-link-blocks">

                            {this.alphabeticItems()}

                        </div>

                        }

                    </div>
                </main>

            )

        } else {

            return <Loadscreen/>

        }

    }

}

export default connect(mapStateToProps)(MedicamentOverview);
