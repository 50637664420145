import React from 'react'

class Image extends React.Component {

    render() {

        if (this.props.afbeelding){

            return (
                <div>
                    <img src={this.props.afbeelding.sizes.large} alt={this.props.alt}/><br/>
                    <small>{this.props.onderschrift}</small><br/>
                </div>
            )

        } else {
            return null
        }
    }
}

export default Image
