import ActionUtility from "../../../utilities/etc/ActionUtility";
import AccountEffect from "./AccountEffect";


export default class AccountAction {

    static REQUEST_SIGNIN = 'AccountAction.REQUEST_SIGNIN';
    static REQUEST_CREATE = 'AccountAction.REQUEST_CREATE';
    static REQUEST_REGISTER = 'AccountAction.REQUEST_REGISTER';
    static REQUEST_CHANGE = 'AccountAction.REQUEST_CHANGE';
    static REQUEST_CHANGE_PASSWORD = 'AccountAction.REQUEST_CHANGE_PASSWORD';
    static REQUEST_SIGNOUT = 'AccountAction.REQUEST_SIGNOUT';
    static REQUEST_TOKEN_TIME = 'AccountAction.REQUEST_TOKEN_TIME';
    static REQUEST_UPDATE_PASSWORD = 'AccountAction.REQUEST_UPDATE_PASSWORD';
    static REQUEST_DEACTIVATE = 'AccountAction.REQUEST_DEACTIVATE';
    static REQUEST_FORGOT_PASSWORD = 'AccountAction.REQUEST_FORGOT_PASSWORD';


    static requestSignIn(loginData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_SIGNIN, AccountEffect.requestSignIn, loginData);
        }

    }

    static requestCreate(createData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_CREATE, AccountEffect.requestCreate, createData);
        }

    }

    static requestRegister() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_REGISTER, AccountEffect.requestRegister);
        }

    }

    static requestChange(changeData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_CHANGE, AccountEffect.requestChange, changeData);
        }

    }

    static requestChangePassword(changePasswordData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_CHANGE_PASSWORD, AccountEffect.requestChangePassword, changePasswordData);
        }

    }

    static requestUpdatePassword(passwordData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_UPDATE_PASSWORD, AccountEffect.requestUpdatePassword, passwordData);
        }

    }

    static requestForgotPassword(forgotData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_FORGOT_PASSWORD, AccountEffect.requestForgotPassword, forgotData);
        }

    }

    static requestDeactivate() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_DEACTIVATE, AccountEffect.requestDeactivate);
        }

    }

    static requestSignOut() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AccountAction.REQUEST_SIGNOUT, AccountEffect.requestSignOut);
        }

    }

}
