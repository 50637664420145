import React from 'react'
import autoTargetBlank from "../../../../utilities/etc/autoTargetBlank";

class TextArea extends React.Component {

    render() {

        const string = autoTargetBlank(this.props.tekst)

        if (this.props.tekst){
            return (
                <div dangerouslySetInnerHTML={{__html: string}} />
            )
        }else{
            return null
        }
    }
}

export default TextArea
