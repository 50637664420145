import ApiEnum from "../../../constants/ApiEnum";

export default class AccountEffect {


    static async requestSignIn(loginData) {

        await fetch(ApiEnum.Api + '/token', {
            method: 'post',
            body: JSON.stringify(loginData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            localStorage.setItem('token', response.token)
            localStorage.setItem('email', response.user_email)
            localStorage.setItem('username', response.user_nicename)
        }).catch(function (error) {
            return {
                error: error,
                message: error.message
            }
        })

        return await fetch(ApiEnum.Api + '/user/id?user_email=' + localStorage.getItem('email'), {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {

            localStorage.setItem('id', response.id)
            localStorage.setItem('firstName', response.firstName)
            localStorage.setItem('lastName', response.lastName)
            localStorage.setItem('tokenDate', new Date().getTime())

            if (response.name) {
                localStorage.setItem('username', response.name)
            }

            if (response.id === null) {

                return {
                    data: 'failed'
                }

            } else {

                return {
                    data: {
                        token: localStorage.getItem('token'),
                        email: localStorage.getItem('email'),
                        username: localStorage.getItem('username'),
                        id: localStorage.getItem('id'),
                        tokenDate: localStorage.getItem('tokenDate')
                    },
                }

            }

        }).catch(function (error) {

            return {
                error: error,
                message: error.message
            }
        })


    }

    static async requestCreate(values) {

        return await fetch(ApiEnum.Api + '/user/create', {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                data: response.data,
                error: response.status
            }
        }).catch(function (error) {
            return {
                error: error,
                message: error.message
            }
        })

    }

    static async requestRegister() {

        return await fetch(ApiEnum.Api + '/user/register', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status.code === 200) {
                return {
                    data: response.data,
                    error: response.status
                }
            } else {
                return {
                    message: response.status.message
                }
            }
        }).catch(function (error) {
            return{
                error: true,
                message: error.message
            }
        })

    }

    static async requestChange(values) {

        return await fetch(ApiEnum.Api + '/user/change?id=' + localStorage.getItem('id'), {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {

            localStorage.setItem('email', response.email)
            localStorage.setItem('username', response.name)
            localStorage.setItem('firstName', response.firstName)
            localStorage.setItem('lastName', response.lastName)

        }).catch(function (error) {

            return {
                error: error,
                message: error.message
            }
        })

    }

    static async requestChangePassword(values) {

        return await fetch(ApiEnum.Api + '/user/change/password?id=' + localStorage.getItem('id'), {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
                return response.json()
            }
        )

    }

    static async requestUpdatePassword(values) {

        return await fetch(ApiEnum.Api + '/user/update/password', {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
                return response.json()
            }
        )

    }

    static async requestForgotPassword(values) {

        return await fetch(ApiEnum.Api + '/user/reset/password', {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
                return response.json()
            }
        )

    }

    static async requestDeactivate() {

        return await fetch(ApiEnum.Api + '/user/deactivate?user_id=' + localStorage.getItem('id'), {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then(() => {

            localStorage.clear()
            return ''

        })


    }

    static async requestSignOut() {

        localStorage.clear()
        return ''

    }

}
