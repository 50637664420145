import ActionUtility from "../../../utilities/etc/ActionUtility";
import InfoEffect from "./InfoEffect";


export default class InfoAction {

    static REQUEST_SINGLE = 'InfoAction.REQUEST_SINGLE';

    static requestSingle() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, InfoAction.REQUEST_SINGLE, InfoEffect.requestSingle);
        }

    }

}
