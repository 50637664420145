import React from 'react'
import {connect} from "react-redux";
import AccountAction from "../../stores/api/account/AccountAction";
import Header from "../_components/Header";
import RouteEnum from "../../constants/RouteEnum";

const mapStateToProps = (state, ownProps) => ({
    ...state.account
});

class AccountPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            password: '',
            passwordConfirm: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault()

        if (this.state.password === this.state.passwordConfirm){

            const updateData = {
                password: this.state.password,
                passwordConfirm: this.state.passwordConfirm
            }

            this.props.dispatch(AccountAction.requestChangePassword(updateData)).then(() => {
                this.props.history.push(RouteEnum.Account)
            })

        }

    }

    render() {

        const {password, passwordConfirm} = this.state

        let passwordLabelClass = 'c-auth-label'
        let passwordConfirmLabelClass = 'c-auth-label'

        if(this.state.password){
            passwordLabelClass += ' c-auth-label--active'
        }

        if(this.state.passwordConfirm){
            passwordConfirmLabelClass += ' c-auth-label--active'
        }

        return (

            <main>
                <Header history={this.props.history}/>

                <div className="o-container o-container--primary">

                    <header className="c-subject-header c-subject-header--detail">
                        <div className="c-subject-header--detail__text">
                            <a onClick={() => this._clickAccount()}><small> &lt; </small><span>Accountinstellingen</span></a>
                            <h1>Wachtwoord wijzigen</h1>
                        </div>
                    </header>

                    <form onSubmit={this.handleSubmit} className="c-auth__form">
                        <div className="c-auth__form__group">
                            <input type="password" id="password" name='password' value={password}
                                   onChange={this.handleChange}/>
                            <label className={passwordLabelClass} htmlFor="text">Nieuw wachtwoord</label>
                        </div>
                        <div className="c-auth__form__group">
                            <input type="password" id="passwordConfirm" name='passwordConfirm' value={passwordConfirm}
                                   onChange={this.handleChange}/>
                            <label className={passwordConfirmLabelClass} htmlFor="text">Wachtwoord bevestigen</label>
                        </div>
                        <button className="c-auth__form__button btn--large btn--green">
                            <span>Wachtwoord wijzigen</span>
                        </button>
                    </form>

                </div>
            </main>

        )
    }

    _clickAccount() {
        this.props.history.push(RouteEnum.Account)
    }
}

export default connect(mapStateToProps)(AccountPassword);

