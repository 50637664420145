import ActionUtility from "../../../utilities/etc/ActionUtility";
import SearchEffect from "./SearchEffect";


export default class SearchAction {

    static REQUEST_SEARCH = 'SearchAction.REQUEST_SEARCH';


    static requestSearch(term) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, SearchAction.REQUEST_SEARCH, SearchEffect.requestSearch, term);
        }

    }

}
